@media screen and (min-width:996px){    

#contact{

background-image: url('./bg.png');
background-repeat: no-repeat;
background-size: cover;
    #div1{
        width:80%;
        margin-left:10%;
        padding-top:20vh;
        padding-bottom:10vh;
        
iframe{
    height:100%;
    width:100%; 
    border:0px solid black;

}

#heading{
    text-align:left;
    margin-left:10%;;
    margin-top:5%;
#h2 , h2{
    background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
    font-weight:bolder;
    font-size:2.2em;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#h2:hover{
  cursor:pointer;
}
p{
    font-size:1.1em;
}
}

table{
    
    .fa , .fab{

        font-size: 30px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
      transition:0.5s;
        margin: 5px 2px;
        border-radius:15px;
      }
      .fa:hover {
          text-decoration: none;
        opacity: 0.7;
    }
    .fab:hover {
        text-decoration: none;
      opacity: 0.7;
  }
  
    .fa-facebook {
        background: #3B5998;
        color: white;
      }
      
      .fa-linkedin {
        background: #55ACEE;
        color: white;
      }
      .fa-whatsapp{
        background: green;
        color: white;
      }
}

    }



    #div2{
        margin-top:5vh;
        padding-bottom:10vh;

width:80%;
margin-left:10%;


h1{    background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
    font-weight:bolder;
    font-size:2.2em;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align:left;

}
  #img2{
      width:80%;height:100%;
  }

  form{
      margin-top:5%;
      width:80%;
      input , textarea{
        background: #DCDCDC;
        padding:25px;
        border-radius:7px;

      }
:-ms-input-placeholder{
    color:black;
}
      button{
        background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
        border:0px solid red;
        border-width: 0px;;
        color:white;
        width:100%;
      }
  }

    }
}


}





@media screen and (min-width:576px) and (max-width:996px){    

    #contact{
    
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-size: cover;
        #div1{
            width:90%;
            margin-left:5%;
            padding-top:20vh;
            padding-bottom:10vh;
            
    iframe{
        height:100%;
        width:110%;
    
    }
    
    #heading{
        text-align:left;
        margin-left:10%;;
        margin-top:5%;
        #h2 , h2{
          background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
          font-weight:bolder;
          font-size:1.5em;
      
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
      }
      #h2:hover{
        cursor:pointer;
      }
    h2{
        background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
        font-weight:bolder;
        font-size:1.5em;
    
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    p{
        font-size:1em;
    }
    }
    
    table{
        
        .fa , .fab{
    
            font-size: 30px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
          transition:0.5s;
            margin: 5px 2px;
            border-radius:15px;
          }
          .fa:hover {
              text-decoration: none;
            opacity: 0.7;
        }
        .fab:hover {
            text-decoration: none;
          opacity: 0.7;
      }
      
        .fa-facebook {
            background: #3B5998;
            color: white;
          }
          
          .fa-linkedin {
            background: #55ACEE;
            color: white;
          }
          .fa-whatsapp{
            background: green;
            color: white;
          }
    }
    
        }
    
    
    
        #div2{
            margin-top:5vh;
            padding-bottom:10vh;
    
    width:90%;
    margin-left:5%;
    
    
    h1{    background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
        font-weight:bolder;
        font-size:2.2em;
    
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align:left;
    
    }
      #img2{
          width:80%;height:100%;
      }
    
      form{
          margin-top:5%;
          width:100%;
          input , textarea{
            background: #DCDCDC;
            padding:25px;
    
          }
    :-ms-input-placeholder{
        color:black;
    }
          button{
            background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
            color:white;
            width:100%;
          }
      }
    
        }
    }
    
    
    }







    @media screen and (max-width:576px){    

        #contact{
        width:100%;
        background-image: url('./bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      
            #div1{
                width:100vw;
                
                padding-top:15vh;
                padding-bottom:10vh;
                
        iframe{
            text-align: center;
            width:100%;
align-items:center;
            margin-left: auto;
            margin-right: auto;
        
        }
        
        #heading{
            text-align:left;
            margin-left:10%;;
            margin-top:5%;
        h2{
            background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
            font-weight:bolder;
            font-size:1.8em;
        
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        #h2 , h2{
          background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
          font-weight:bolder;
          font-size:1.8em;
      
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
      }
      #h2:hover{
        cursor:pointer;
      }
        p{
            font-size:1em;
        }
        }
        
        table{
            
            .fa , .fab{
        
                font-size: 30px;
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
              transition:0.5s;
                margin: 5px 2px;
                border-radius:15px;
              }
              .fa:hover {
                  text-decoration: none;
                opacity: 0.7;
            }
            .fab:hover {
                text-decoration: none;
              opacity: 0.7;
          }
          
            .fa-facebook {
                background: #3B5998;
                color: white;
              }
              
              .fa-linkedin {
                background: #55ACEE;
                color: white;
              }
              .fa-whatsapp{
                background: green;
                color: white;
              }
        }
        
            }
        
        
        
            #div2{
                margin-top:5vh;
                padding-bottom:10vh;

        
        width:100vw;
        
        
        
        h1{    background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
            font-weight:bolder;
            font-size:1.5em;
        
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align:left;
        
        }
          #img2{
              width:100%;height:100%;
          }
        
          form{
              margin-top:5%;
              width:100%;
              input , textarea{
                background: #DCDCDC;
                padding:25px;
        
              }
        :-ms-input-placeholder{
            color:black;
        }
              button{
                background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
                color:white;
                width:100%;
              }
          }
        
            }
        }
        
        
        }


@media screen  and (min-width:996px){
#iot_Carousel{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    img{
        width:80%;
        margin-left: 10%;;
    }

    .fa{color:black;
font-size:10em;

    }
}

}



@media screen  and (min-width:576px) and (max-width:976px){
    #iot_Carousel{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        img{
            width:100%;
        
        }
    
        .fa{color:black;
    font-size:2em;
    
        }
    }
    
    }


@media screen  and (max-width:576px){
    #iot_Carousel{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        img{
            width:100%;
        
        }
    
        .fa{color:black;
    font-size:2em;
    
        }
    }
    
    }

@media screen and (min-width:996px){
    


#applications_blogs{
    background-color:black;
    background-size:cover;
    background-repeat: no-repeat;
    padding-top:5%;
    padding-bottom:5%;
    
    min-height:100vh;
    
    #applicationsheading{
        color:white;
        text-align:center;
       
        h1{
            font-size:5em;
            span{
                color:#1DDAF2;
                font-weight: BOLDER;
            }
        }
        p{
            font-size:1.5em;
        }
    }


    .card{
        background-color:white;
        color:black;
        width:95%;
        padding:2%;
        margin-left: 2%;;
        margin-right:2%;
        border-radius: 25px;;
        height:55vh;
        #img{
           width:100%;
           height:30vh;

            border-radius: 25px;;

        }
        .carousel_div{
            margin-top:2vh;
            width:95%;
            margin-left:3%;  
            overflow-y: auto;

            h1{
                font-size:1.5em;
               color:rgb(68, 7, 7);

            }
            p{
                font-size:1.1em;
                text-align: left;
              
            }
        }
        .carousel_div::-webkit-scrollbar {
            width: 0.2em;
            color:none;
            
            background-color: none;
          }
          ::-webkit-scrollbar-thumb {
            background: grey;
          }
    }
 
    .carousel-inner{
        width:80%;
        margin-left:10%;
    }

    .carousel{
        margin-left:15%;
        width:70%;
        margin-top:5%;
        min-height:65vh;
        #img{
            height:10vh;height:30vh;
           
        }

    }
    .carouselphone{
        display:none;

    }

  
   
}

}




@media screen and (min-width:576px) and (max-width:996px){
    


    #applications_blogs{
    
        background-color:black;
        background-size:cover;
        background-repeat: no-repeat;
        padding-top:5%;
        padding-bottom:10%;
        

        #applicationsheading{
            color:white;
            text-align: left;
margin-left: 10%;;
            h1{
                font-size:3em;

                span{
                    color:#1DDAF2;
                    font-weight: bolder;

                
                }
            }
            p{
                font-size:1em;
            }
        }
    
    
        .card{
            background-color:white;
            color:black;
            width:98%;
            padding:1%;
            margin-left: 1%;;
            margin-right:1%;
            border-radius: 25px;;
            height:60vh;
            #img{
               width:100%;
               height:30vh;
    
                border-radius: 25px;;
    
            }
            .carousel_div{
                margin-top:2vh;
                width:95%;
                margin-left:3%;
                overflow-y: auto;
            
                h1{
                    font-size:1em;
    
                }
                p{
                    font-size:0.8em;
                    text-align: left;
                }
            }
            .carousel_div::-webkit-scrollbar {
                width: 0.2em;
                color:none;
                
                background-color: none;
              }
              ::-webkit-scrollbar-thumb {
                background: grey;
              }
        }
    
    
        .carousel-inner{
            width:80%;
            margin-left:10%;
        }
    
        .carousel{
            
            width:100%;
            margin-top:5%;
            min-height:60vh;
            #img{
                height:10vh;height:30vh;
               
            }
    
        }
        .carouselphone{
            display:none;
    
        }
        
       
    }
    
    }


    @media screen and (max-width:576px){
    


        #applications_blogs{
            background-color:black;
            background-size:cover;
            background-repeat: no-repeat;
            padding-top:5vh;
            padding-bottom:10%;
            
            
            #applicationsheading{
                color:white;
                h1{
                    font-size:2.5em;
                    span{
                        color:#1DDAF2;font-weight:bolder;
                    }
                }
                p{
                    font-size:1.5em;
                }
                p{
                    width:90%;
                    margin-left:5%;
                    font-size:1.2em;
                }
            }
        
        
            .card{
                background-color:white;
                color:black;
                width:90%;
                padding:1%;
                margin-left: 5%;;
                margin-right:5%;
                border-radius: 25px;;
                height:55vh;
                
                #img{
                   width:100%;
                   height:30vh;
        
                    border-radius: 25px;;
        
                }
                .carousel_div{
                    margin-top:2vh;
                    width:95%;
                    margin-left:3%;
                
overflow-y: auto;
                    h1{
                        font-size:1em;
        
                    }
                    p{
                        font-size:0.8em;
                        text-align: left;
                    }
                    
                }
                .carousel_div::-webkit-scrollbar {
                    width: 0.2em;
                    color:none;
                    
                    background-color: none;
                  }
                  ::-webkit-scrollbar-thumb {
                    background: grey;
                  }
            }
        
            .carousel-inner{
                width:80%;
                margin-left:10%;
            }
        
            .carousel{
                
                width:100%;
                margin-top:5%;
                min-height:50vh;
                #img{
                    height:10vh;height:30vh;
                   
                }
             
        
            }
            .carouselphone{
                display:block;
        
            }
            .carouseldesktop{
                display: none;
            }
            
           
        }
        
        }

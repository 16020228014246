@media screen and (min-width:996px){
    

#blog_data3{
min-height:100vh;
padding-bottom:20vh;
.box{
    width:100%;
    height:65vh;
    background:  linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./img3.png');

    background-blend-mode: darken;
    background-repeat: no-repeat;
    background-size: cover;
position: relative;

.div{ 
    margin-left:10%;
    border:0px solid red;
    position: absolute;
    bottom:5vh;
    width:80%;

    button{
        background-color: white;;
        color:black;
        float:left;
        border:0px solid white;
        font-size: 1em;
      padding:12px;;
      border-radius: 10px;;

    }

    h1{
        color:white;
        margin-top:3%;
        margin-bottom:2%;
        padding-bottom:3%;
        width:50%;
        font-size:2.1em;
border-bottom: 1px solid white;
   text-align: left;;
    }

    p{
        width:90%;
        color:white;
        font-size: 1.3em;;
        text-align: left;
    }
}










}

.div1{
    margin-top:10vh;
    text-align: left;
    h3{
        font-size:2em;
        font-weight: bold;
        margin:2%;
    }
    p{
        font-size:1.2em;
        margin:2%;
    }
img{
    max-height:90%;width:90%;
}

#top{
    margin-top:5vh;
    margin-left: auto;
    margin-left: 40%;;
    margin-right: auto;
    text-align: center;
    position:relative;
}
}

}

}






@media screen and (min-width:576px) and (max-width:996px){
    

    #blog_data3{
    min-height:100vh;
    padding-bottom: 20vh;;
    .box{
        width:100%;
        height:65vh;
        background:  linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./img3.png');

    background-blend-mode: darken;
        background-repeat: no-repeat;
        background-size: cover;
    position: relative;
    
    .div{ 
        margin-left:10%;
        border:0px solid red;
        position: absolute;
        bottom:5vh;
        width:80%;
    
        button{
            background-color: white;;
            color:black;
            float:left;
            border:0px solid white;
            font-size: 0.8em;
          padding:10px;;
          border-radius: 10px;;
    
        }
    
        h1{
            color:white;
            margin-top:5%;
            margin-bottom:2%;
            padding-bottom:3%;
            width:65%;
            font-size:1.6em;
    border-bottom: 1px solid white;
       text-align: left;;
        }
    
        p{
            width:95%;
            color:white;
            font-size: 1.1em;;
            text-align: left;
        }
    }
    
    
    
    
    
    
    
    
    
    
    }
    
    
    .div1{
        margin-top:10vh;
        text-align: left;
        h3{
            font-size:2em;
            font-weight: bold;
            margin:2%;
        }
        p{
            font-size:1.2em;
            margin:2%;
        }
        img{
            height:100%;width:100%;
        }
    
    #top{
        margin-top:5vh;
        margin-left: auto;
        margin-left: 40%;;
        margin-right: auto;
        text-align: center;
        position:relative;
    }
    }


    }
    
    }














    @media screen and (max-width:576px){
    

        #blog_data3{
        min-height:100vh;
        margin-bottom:15vh;
        .box{
            width:100%;
            height:65vh;
            background:  linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./img3phone.png');

            background-blend-mode: darken;
            background-repeat: no-repeat;
            background-size: cover;
        position: relative;
        
        .div{ 
            margin-left:10%;
            border:0px solid red;
            position: absolute;
            bottom:5vh;
            width:80%;
        
            button{
                background-color: white;;
                color:black;
                float:left;
                border:0px solid white;
                font-size: 0.8em;
              padding:10px;;
              border-radius: 10px;;
        
            }
        
            h1{
                color:white;
                margin-top:10%;;
                margin-bottom:5%;
                padding-bottom:5%;
                width:80%;
                font-size:1.4em;
        border-bottom: 1px solid white;
           text-align: left;;
            }
        
            p{
                width:100%;
                color:white;
                font-size: 0.9em;;
                text-align: left;
            }
        }
        
        
        
        
        
        
        
        
        
        
        }
        
        .div1{
            margin-top:5vh;
            text-align: left;
            h3{
                font-size:1.4em;
                font-weight: bold;
                margin:2%;
            }
            p{
                font-size:1em;
                margin:2%;
            }
            img{
                height:90%;width:100%;
                margin-top:5%;
            }
        
        #top{
            margin-top:5vh;
            margin-left: auto;

            margin-right: auto;
margin-left:3%;
            text-align: center;
            position:relative;
        }
        }
        img{
            height:100%;width:100%;
            margin-top:5vh;
        }
        }
        
        }
        


@media screen and (min-width:996px) {
    
    #myCarousel2{
        margin-top: 20vh;;
        padding-top:0%;
        width:80%; float:right;
     
    
      #img2{
        
          
          width:95%;
          margin-left:2%;
          margin-right: 2%;
     border-radius: 25px;;
    border:2px solid black;
      }
    }
    }
    
    
    @media screen and (min-width:576px) and (max-width:996px) {
        #myCarousel2{
            margin-top: 10vh;;
            padding-top:0%;
            width:100%; float:right;
         
        
          #img2{
            
              
              width:95%;
              margin-left:2%;
              margin-right: 2%;
         
        
          }
    }
    }
    
    
    
    
    @media screen and  (max-width:576px) {
        #myCarousel2{
            margin-top: 5vh;;
            padding-top:0%;
            width:100%; align-items: center;
            text-align: center;
         border-radius:10px;
        
          .img2{
            
              
              width:95%;
              margin-left:2%;
              margin-right: 2%;
              border:2px solid black;
        border-radius: 10px;;
          }

          span{
            background-color: grey;
            color:black;
            margin:3%;
            padding:1%;
        }
    }
    }


@media screen and (min-width:576px) {

.error{
    height:100vh;
    background-image: url('./bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    
  
img{
    height: 90vh;
    margin-top:10vh;;
}
.div{
    width:100%;
   margin-top:30vh;
    height: 100vh;;
    h2{
        width:100vw;
        font-size:2.5em;
        font-weight: 900;
        float: left;
        text-align: left;;
        margin-left: 10%;;
span{
    font-size: 4em;;
}

    }
    button{
        
        font-size:1.3em;
        padding:2%;
        float:left;
        margin-left: 10%;;
        
    }
}
}
}

@media screen and (max-width:576px) {

    .error{
        
        background-image: url('./bgphone.png');
        background-size: cover;
        background-repeat: no-repeat;
        
      
    img{
        height: 50vh;
        
    }
    .div{
       margin-top:20vh;
margin-bottom: 0vh;;
margin-bottom:5vh;
        
        h2{
            font-size:1.5em;
            font-weight: 900;
            float: left;
            text-align: left;;
            margin-left: 10%;;
    span{
        font-size: 3em;;
    }
    
        }
        button{
            
            font-size:1.3em;
            padding:2%;
            float:left;
            margin-left: 10%;;
            
        }
    }
    }
    
}
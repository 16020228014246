
@media screen and (min-width:996px){
#details{
  /*  min-height:100vh;
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top:20vh;
    padding-bottom:20vh; */
    

    border-radius:30px;
    #div{
        background-color:white;
        padding:5%;
        border-radius: 25px;
        box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;

width:100%;






        h1{
            text-align: left;
font-weight: bolder;
color: #3a3a3a;

            font-size:2em;
        }
        p{
            text-align: left;
            
            color: #3a3a3a;
            font-size:1.1em;
        }
        

form{
margin-top:5vh;
    label{
        float:left;
        text-align: left;;
    }
    input{
box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;

border:0px solid #3a3a3a;
        border-radius:5px;
        padding:5%;
    }
    ::-webkit-input-placeholder { /* Edge */
        color: #3a3a3a;
      }
      .form-row {
          margin-top:5px;
          .col{
            input{
                box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;
                border:0px solid #5BA5FD;
                        border-radius:5px;
                      padding:10px;

                    }
                    #rowinput{
                        padding:10px;
                      }
          }
       

      }
   
    button{
        margin-top:2vh;
        background-color: #5BA5FD;;
border:0px solid red;
        padding-left:3%;
        padding-top:1%;
        padding-top:1%;
        border-radius:10px;
        
        padding-right:3%;
        font-size:1.1em;
    }
    
   
}


    }
}

}
















@media screen and (min-width:576px) and (max-width:996px) {
    #details{
        
        
        #div{
            background-color:white;
            padding:5%;
            border-radius: 25px;
            box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;
    width:100%;
    
            h1{
                text-align: left;
    
                font-size:1.5em;
            }
            p{
                text-align: left;
    
                font-size:1em;
            }
            
    
    form{
    margin-top:5vh;
        label{
            float:left;
            text-align: left;;
        }
        input{
    box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;  font-size:1em;
            
        }
        input{
            box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;
            
            border:0px solid #3a3a3a;
                    border-radius:5px;
                    padding:2%;
                }
                ::-webkit-input-placeholder { /* Edge */
                    color: #3a3a3a;
                  }
        button{
            margin-top:5vh;
            background-image: linear-gradient(to right, #1FD9F3,#5BA5FD);
            padding-left:3%;
            padding-top:1%;
            padding-top:1%;
            border-radius:10px;
            
            padding-right:3%;
            font-size:1.1em;
        }
    }
    
    
        }
    }
    
}


















@media screen and  (max-width:576px) {
    #details{
     
        
        #div{
            background-color:white;
            padding:5%;
            
            border-radius: 25px;
            box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;
    width:100%;
    
            h1{
                text-align: left;
    
                font-size:1.2em;
            }
            p{
                text-align: left;
    
                font-size:0.9em;
            }
            
    
    form{
    margin-top:5vh;
        label{
            float:left;
            text-align: left;;
        }
  

        input{
            box-shadow: 3.799999952316284px 4.75px 54.14999771118164px 0px #00000040;
            
            border:0px solid #3a3a3a;
                    border-radius:5px;
                    padding:5%;
                }
                ::-webkit-input-placeholder { /* Edge */
                    color: #3a3a3a;
                  }
        button{
            margin-top:0vh;
            
            background-color: #5BA5FD;;;
            padding-left:3%;
            padding-top:1%;
            padding-top:1%;
            border-radius:10px;
            
            padding-right:3%;
            font-size:0.9em;
        }
    }
    
    
        }
    }
    
    }
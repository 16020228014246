
.topheader2 {
  
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}
.navbar-close{
  display: none;
}
.navbar-open{
  display: block;
}
.acc-close{
  display: none;
}
.acc-open{
  display: block;
}

@media screen and (min-width:996px){
 
.topheader2{
  
.phonenav{
  display: none;
}

.navbar{
  background-color: #484949;
}
.nav-item{
    padding:1%;
}
a{
  font-size:1.1em;
  color:white;
  float:left;
}
.dropdown-item{
  font-size:1em;
}
.logofooter{
  width:200px;
  margin-top:1%;
  margin-bottom:1%;
  margin-left:10%;
}

#brand{
  font-family: 'Noto Sans', sans-serif;
margin-left:1%;
margin-right:1%;
color:white;
}
ul{
  list-style-type:none;
}
#branddiv{
margin-left:5%;
 width:40%;
 margin-top: auto;
 margin-bottom: auto;
 li{
   margin-left:0px;
   margin-right:0px;
   float:left;
  
  }
  
a:hover{
  
  color:#1DDAF2;
}

}
table{
  width:100%;
}
#btn{
float:right;


margin-right:10%;
margin-left:10%;
font-size:1.8em;
sup{
 padding-top:10px;
}


i{
  color:white;
  margin:0px;
padding-top:0px;
}
align-items: right;
color:white;
margin-top: auto;
margin-bottom: auto;

padding:0px;
padding-top:0px;

font-family: 'Noto Sans', sans-serif;
margin-top: auto;
margin-bottom: auto;
border-width: 0px;;

}
#btn:hover{
background: none;
color:#1DDAF2;
padding:0px;
i{
  color:#1DDAF2;
  margin:0px;
  padding:0px;
}
font-size:2em;
}

#tempnav{
  display: none;
}

#heading{
    width:100%;
}

#accordion{
    width:100%;
button{
    width:100%;
}
.card{
    width: 100%;
}
  }
.navbarrow{




}
 
  #collapsibleNavbar{
    
width:25%;
overflow: hidden;
background-color: rgba(37, 34, 34, 0.9);
border-radius:25px;
margin:1%;
float:right;
padding:5px;

#producthead{
color:white;
margin-top:3%;
font-size:1.5em;

}
hr{
color:white;
width:120%;
background-color: white;
}

  }

  #login_dropdown{
    
    width:25%;
    overflow: hidden;
    background-color: rgba(37, 34, 34, 0.9);
    border-radius:25px;
    margin:1%;
    float:right;
    
    padding:5px;
    
    
    #producthead{
    color:white;
    margin-top:3%;
    font-size:1.5em;
    
    }
    hr{
      color:white;
      width:120%;
      background-color: white;
    }
    
        }
    



  .row2{
      width:100%;

  
  
   
      .btn{
          width:100%;
      }


      #ul1{
        padding-left:10px;
          float:left;
          width:100%;
          .heading1{
              color:rgb(184, 180, 180);
              font-size:1.2em;
              padding:3%;;

text-align: left;
i{
  padding-left:10px;
  text-align:right;
}

          }
          .heading1:hover{
color:white;
          }
          a{
              background: none;
              border:0px solid white;
              padding:0px;
              text-align: left;width:100%;
          }
          a:hover{
              text-decoration: none;
          }
          
          .collapse{
              text-align: left;
              width:100%;
li{
width:100%;
}

          }
          
          
          li{
        
              color:grey;width:100%;
              font-size: 1.1em;
          padding:1%;
          padding-left:10%;
          width:100%;

              transition:0.2s;
              
          }
      
          li:hover{
              color:black;
              color:#1DDAF2;
           ;cursor:pointer;
              padding:1%;
              font-size:1.1em;padding-left:10%;
              
          
              

          }

      }
    
.navbar-toggler{
margin-left:0px;
float:right;
}

      li{
        
          color:grey;width:100%;
          font-size: 1.1em;
padding:0px;
          
      }

  }

nav{

.acc-close{ 
background-color: none;
color:#1DDAF2;

display: none;



i{
  color:white;
  font-size: 2em;;
}
}

.acc-open{ 
  background-color: none;
  color:#1DDAF2;
  
  

  
  
  i{
    color:white;
    font-size: 2em;;
  }
  }




.navbarrow{
margin-top:auto;
margin-bottom: auto;

display: flex;
justify-content: center;
align-items: center;
height:100%;
#desktop_nav{


#loginbutton{

animation: effect 2s infinite;
color:white;
margin-top:auto;
margin-bottom: auto;
vertical-align: middle;

}
#loginbutton:hover{
color:#1DDAF2;
border-color:#1DDAF2;
background: none;
animation: none;
}
}

}

@keyframes effect {
0% {
 border-color:grey;
}
50% {
border-color:white;

 
}
100% {
border-color:grey;

 
}
}


}
#phone_login{
display: none;
}  

}



}




@media screen and (min-width:576px) and (max-width:996px){
  .topheader2
{  
  .phonenav{
    display: none;
  }

  

  #branddiv{
    margin-left:20%;;
    width:auto;
  }
  #logout{
    display: none;
  }
  

  .navbar{
    background-color: #262727;
  }
  a{
    font-size:1.1em;
   color:white;
    float:left;
  }
  .logofooter{
    width:150px;
    margin-top:4%;
    margin-bottom:4%;
    margin-left:10%;
  }
  
  #brand{
    font-family: 'Noto Sans', sans-serif;
  margin-left:0%;
  margin-right:0%;
  color:white;
  }
  #branddiv{
    margin-left:15%;;
  }
  #btn{
  float:right;
  
  float:right;
  margin-left:5%;
  font-size:1.1em;
  sup{
    padding-top:10px;
   }
  align-items: right;
  background-color: #1DDAF2;
  border-radius:20px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  color:black;
  font-family: 'Noto Sans', sans-serif;
  display:none;
  }

  ul{
    list-style-type:none;
  }
  #branddiv{
    display: none;;
    margin-left:4%;;
   width:50%;
   margin-top: auto;
   margin-bottom: auto;
   color:white;
   font-size:0.8em;
   li{
     margin-left:0px;
     margin-right:0px;
     color:white;

     float:left;
   }
  }
  table{
    width:100%;
  }
  #tempnav{
    display: none;
  }
 
  #collapsibleNavbar{
    border:2px solid white;
width:40%;
max-height: 80vh;
overflow: scroll;
background-color: rgb(37, 34, 34);
border-radius:25px;
margin:2%;
float:right;
padding:5px;

#producthead{
color:white;
margin-top:3%;
font-size:1.5em;

}
hr{
color:white;
width:120%;
background-color: white;
}

  }
  
  .row2{
      width:100%;

   
      .btn{
          width:100%;
      }

      #ul1{
          float:left;
          width:100%;
          .heading1{
              color:rgb(184, 180, 180);
              font-size:1.1em;
              padding:2%;;
text-align: left;
i{
  padding-left:10px;
  text-align:right;
}

          }
          .heading1:hover{
color:white;
          }
          a{
              background: none;
              border:0px solid white;
              padding:0px;
              text-align: left;width:100%;
          }
          a:hover{
              text-decoration: none;
          }
          
          .collapse{
              text-align: left;
              width:100%;
          }
          
          
          li{
        
              color:grey;width:100%;
              font-size: 1em;
          padding:1%;
          padding-left:10%;
          width:100%;

              transition:0.2s;
              
          }
      
          li:hover{
              color:black;
              background-color:white;;cursor:pointer;
              padding:1%;
              font-size:1em;padding-left:10%;
          
              

          }

      }


      li{
        
          color:grey;width:100%;
          font-size: 1em;
padding:0px;
          
      }

  }
  
  #rightnav{
      text-align:left;
  }



  #phone_login{
    margin-top:1vh;
    border-top:1px solid grey;
    border-bottom
    
    
    :1px solid grey;
    
  width:100vw;   
  font-family: 'Noto Sans', sans-serif;
            #userbutton{
              background-color: none;
              color:#1DDAF2;
            padding:1%;
              
              
             font-size:1.5em; 
              
              i{
                color:white;
             
              }
            }
            
              
            #userbutton:hover > i{
              background-color: none;
              color:#1DDAF2;
            
            }
            #loginbutton{

              background: none;
              border:0px solid white;
              color:white;
            margin:5px;
            color:rgb(226, 132, 132);
            }
            #loginbutton:hover{
              color:#1DDAF2;
              border-color:#1DDAF2;
              background: none;
              animation: none;
            }
            @keyframes effect {
              0% {
                transform: translateX(0px) rotate(0deg);
            }
            
            20% {
                transform: translateX(-4px) rotate(-4deg);
            }
            
            40% {
                transform: translateX(-2px) rotate(-2deg);
            }
            
            60% {
                transform: translateX(4px) rotate(4deg);
            }
            
            80% {
                transform: translateX(2px) rotate(2deg);
            }
            
            100% {
                transform: translateX(0px) rotate(0deg);
            }
            }
          }

          #loginbutton  #userbutton{
            display: none;
          }
        
#desktop_nav{
display: none;
}

  
}
}

@media screen and (max-width:576px){
  .topheader2
{  

  #branddiv{
    margin-left:20%;;
    width:auto;
  }
  #desktop_nav{
    display: none;
  }
  #logout{
    display: none;
  }

.phone{
  float:left;
}
  .navbar{
    background-color: #262727;
  }
  ul{
    float: left;;
    text-align: left;;
  }
  a{
    font-size:1em;
    color:white;
    float:left;
  }
  .logofooter{
    width:150px;
    margin-top:2%;
    margin-bottom:2%;
    margin-left:10%;
  }
  #branddiv{
    display: none;
  }
  #brand{
    font-family: 'Noto Sans', sans-serif;
  margin-left:2%;
  margin-right:2%;
  color:white;
  }
  #branddiv{
    margin-left:25%;;
  }
  #btn{
  float:right;
  right:50px;;
  margin-right:2%;
  font-size:1em;
  align-items: right;
  background-color: #1DDAF2;
  border-radius:20px;
  padding:5px;
  padding-left:30px;
  padding-right:30px;
  color:black;
  font-family: 'Noto Sans', sans-serif;
  display: none;
  }
  
  #tempnav{
    display: block;
    text-align: left;
   
    a{
      text-align:left;
      float: left;;
    }
    
    ul{
      text-align: left;

    }
  }

  #collapsibleNavbar{

    position:fixed;
top:0px;
    border-top:2px solid grey;
width:100%;
height: 100vh;
overflow: scroll;

background-color: rgba(51, 51, 51,0.95);

float:right;

padding-bottom:10px;
padding:5px;
padding-bottom:5%;


hr{
display: none;
}
#producthead{
color:white;
margin-top:3%;
font-size:1.5em;
text-align: center;
float: middle;

}
hr{
color:white;
width:120%;
background-color: white;
}

  }

  .row2{
      width:100vw;

   
      .btn{
          width:100%;
      }

      #userbutton{
        background-color: none;
        color:white;
      }
      #ul1{
        
          width:100vw;
          .heading1{
              color:rgb(184, 180, 180);
              font-size:1.1em;
              padding:4%;;
              border-top: 1px solid grey;
              border-bottom: 1px solid grey;;
              color:rgb(106, 216, 211);
              border-top: 1px solid grey;;
              border-bottom: 1px solid grey;;
text-align: center;
i{
  padding-left:10px;
  text-align:right;
}

          }
          .heading1:hover{
color:rgb(106, 216, 211);
border-top: 1px solid rgb(106, 216, 211);;
border-bottom: 1px solid rgb(106, 216, 211);;

              
          }
          a{
              background: none;
              border:0px solid white;
              padding:0px;
              text-align: left;width:100%;
          }
          a:hover{
              text-decoration: none;
          }
          
          .collapse{
              text-align: left;
              width:100%;
          }
          
          
          li{
        
              color:rgba(255, 255, 255, 0.774);width:100%;
              font-size: 1em;
          padding:4%;
          
          width:100vw;
          border-top:1px solid rgb(122, 110, 110);
            
              transition:0.2s;
              text-align: center;
          }
      
          li:hover{
              color:black;
              background-color:white;;cursor:pointer;
              padding:1%;
              font-size:1em;padding-left:10%;
            
          
              

          }
        

      }


      li{
        
          color:grey;width:100%;
          font-size: 1em;
padding:0px;
          
      }
    }

#phone_login{
margin-top:1vh;
border-top:1px solid grey;
border-bottom


:1px solid grey;

width:100vw;   
font-family: 'Noto Sans', sans-serif;
        #userbutton{
          background-color: none;
          color:#1DDAF2;
        padding:3%;
          
          
         font-size:1.5em; 
          
          i{
            color:white;
         
          }
        }
        
          
        #userbutton:hover > i{
          background-color: none;
          color:#1DDAF2;
        
        }
        #loginbutton{

          background: none;
          border:0px solid white;
          color:white;
        margin:5px;
        color:rgb(226, 132, 132);
        }
        #loginbutton:hover{
          color:#1DDAF2;
          border-color:#1DDAF2;
          background: none;
          animation: none;
        }
        @keyframes effect {
          0% {
            transform: translateX(0px) rotate(0deg);
        }
        
        20% {
            transform: translateX(-4px) rotate(-4deg);
        }
        
        40% {
            transform: translateX(-2px) rotate(-2deg);
        }
        
        60% {
            transform: translateX(4px) rotate(4deg);
        }
        
        80% {
            transform: translateX(2px) rotate(2deg);
        }
        
        100% {
            transform: translateX(0px) rotate(0deg);
        }
        }
      }
        
        






}
}

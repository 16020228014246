
@media screen and (min-width:996px) {
    

#last{
    
    
    background-color: white;;
    #div1{
        width:100vw;
        padding:5%;
        text-align: center;
        align-items: center;

        h1{
          width:100%;
            font-size: 2.5em;;   align-items: center;

            font-weight: bolder;        text-align: center;

        }
        p{
            font-size: 1.5em;;          width:100%;   align-items: center;

        }
        
       
    }

    #video{
        width:50%;
margin-top:3%;

        
        height:50vh;
        
    }

#div2{
    width:100vw;
    height:20vh;
        padding:5%;
        h1{
            font-size: 2.5em;;
            font-weight: bolder;
            span{
                color:#1DDAF2;
            }
        }
        p{
            font-size: 1.5em;;
        }
   


}




}

}




@media screen and (min-width:576px) and (max-width:996px) {
    

    #last{
        height:100vh;
        
        #div1{
            width:100vw;
            padding:5%;
            h1{
                font-size: 2em;;
                font-weight: bolder;
            }
            p{
                font-size: 1.3em;;
            }
            
           
        }
    
        #video{
            width:50%;
    margin-top:3%;
    
            
            height:50vh;
        }

        #div2{
        width:100vw;
        padding:5%;
        h1{
            font-size: 2em;;
            font-weight: bolder;
            span{
                color:#1DDAF2;
            }
        }
        p{
            font-size: 1.2em;;
        }
    
    #main {
        margin: 50px 0;
      }
      
      #main #faq .card {
        margin-bottom: 30px;
        font-weight: 900;
        
        border: 0;
      }
      
      #main #faq .card .card-header {
        border: 0;
        -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
                box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
        border-radius: 2px;
        padding: 0;
        border:2px solid grey;
        font-weight: 900;
        font-weight:bolder;

border-radius: 25px;;    }
      
      #main #faq .card .card-header .btn-header-link {
        color: #fff;
        display: block;
        text-align: left;
        
        color: #222;
        padding: 20px;
      }
      
      #main #faq .card .card-header .btn-header-link:after {
        content: "\f106";
        font-family: 'FontAwesome';
        font-weight: 900;
        

        float: right;
        
      }
      
      #main #faq .card .card-header .btn-header-link.collapsed {
        
        font-weight: bolder;;
      }
      
      #main #faq .card .card-header .btn-header-link.collapsed:after {
        content: "\f107";
      }
      
      #main #faq .card .collapsing {
        border:2px solid grey;
        border-radius:25px;
        text-align: left;;

        line-height: 30px;
      }
      
      #main #faq .card .collapse {
        border: 0;
      }
      
      #main #faq .card .collapse.show {
        border:2px solid grey;
        border-radius:25px;
text-align: left;;
        
        line-height: 30px;
        color: #222;
      }


}



    }
    
    
}


    
@media screen and (max-width:576px) {
    

    #last{
        height:100vh;
        #div1{
          margin-top:5%;
            width:100vw;
            padding:5%;
            h1{
                font-size: 1.5em;;
                font-weight: bolder;
            }
            p{
                font-size: 1em;;
            }
            
           
        }
    
        #video{
            width:100%;
    margin-top:3%;
    
            
            height:50vh;
        }


        #div2{
        width:100vw;
        padding:5%;
        h1{
            font-size: 1.5em;;
            font-weight: bolder;
            span{
                color:#1DDAF2;
            }
        }
        p{
            font-size: 1em;;
        }
    
    #main {
        margin: 50px 0;
      }
      
      #main #faq .card {
        margin-bottom: 30px;
        font-weight: 900;
        
        border: 0;
      }
      
      #main #faq .card .card-header {
        border: 0;
        -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
                box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
        border-radius: 2px;
        padding: 0;
        border:2px solid grey;
        font-weight: 900;
        font-weight:bolder;

border-radius: 25px;;    }
      
      #main #faq .card .card-header .btn-header-link {
        color: #fff;
        display: block;
        text-align: left;
        
        color: #222;
        padding: 20px;
      }
      
      #main #faq .card .card-header .btn-header-link:after {
        content: "\f106";
        font-family: 'FontAwesome';
        font-weight: 900;
        

        float: right;
        
      }
      
      #main #faq .card .card-header .btn-header-link.collapsed {
        
        font-weight: bolder;;
      }
      
      #main #faq .card .card-header .btn-header-link.collapsed:after {
        content: "\f107";
      }
      
      #main #faq .card .collapsing {
        border:2px solid grey;
        border-radius:25px;
        text-align: left;;

        line-height: 30px;
      }
      
      #main #faq .card .collapse {
        border: 0;
      }
      
      #main #faq .card .collapse.show {
        border:2px solid grey;
        border-radius:25px;
text-align: left;;
        
        line-height: 30px;
        color: #222;
      }


}




        
    }
    
    }

@media screen and(min-width:996px){
#educationSec{

    .div1{
        height:100vh;
        background-color: black;;
background-image: url('./bg.png');
background-size: cover;
background-repeat:no-repeat ;

        width:100vw;
        #nesteddiv1{
margin-top:30vh;
margin-left:10%;

width:70%;
text-align: left;;
            h1{
                font-size:2.5em;
                color:white;
                span{
                    color:#1DDAF2;
                }
            }

            h2{
                font-size:4.5em;
                font-weight:600;

                font-family: 'Montserrat', sans-serif;
                color:white;
            }
            p{
                font-weight:300;
                
                font-size:1.6em;
                color:white;

            }
        }
    }
    #img2{
        
        display: none;
        float:left;
        
        width:100%;
    }
    #phonep{
        display: none;
    }
#img1{
    max-width:100%;
    margin-top:30vh;
    
    height:50vh;
        
    
}

#row2{
    height:100vh;
       
   width:100vw;
  background-image: url('./img2.png');

   background-repeat: no-repeat;
      background-size: cover;
      text-align: left;
      font-family: 'Noto Sans', sans-serif;
      
      display: flex;
      justify-content: center;
      align-items: center;

  }

  #div2{
    
margin-left:30%;
width:40%;
align-items: center;
text-align: center;
    h1{
        text-align: center;
        font-size:5em;
        color:black;
        font-weight: bolder;
    }

    p{
      font-weight: 400;
      font-weight: bolder;
    color:grey;
        font-size:1.6em;
    }
    #btn{
        text-align: center;
        background-color:black;
        color:white;
        border-radius:20px;
        font-size:1.2em;
        margin-left: auto;
        margin-right: auto;
        padding:3%;

    }
}
  
#div2img{
    margin-top:30vh;
    max-width:100%;
    text-align: center;
    margin-left:auto;
margin-right:auto;
margin-left: 20%;;
}
    
}
}




@media screen and (min-width:576px) and (max-width:996px){
    #educationSec{
    
        .div1{

            height:100vh;
            background-color: black;;
            background-image: url('./bg.png');
background-size: cover;
background-repeat:no-repeat ;

    width:100vw;
            #nesteddiv1{
    margin-top:30vh;
    margin-left:10%;
    width:70%;
    text-align: left;;
                h1{
                    font-size:2em;
                    color:white;
                    span{
                        color:#1DDAF2;
                    }
                }
    
                h2{
                    font-size:3.5em;
                    font-weight:600;
                    font-family: 'Montserrat', sans-serif;
                    color:white;
                }
                p{
                    font-size:1.2em;
                    color:white;
    
                }
            }
        }
    #img1{
        margin-top:30vh;
        float:left;
        
        height:50vh;
    }
    #img2{
        
        display: none;
        float:left;
        
        width:100%;
    }
    #phonep{
        display: none;
    }
        


    #row2{
      
        height:100vh;
        width:100vw;
          background-image: url('https://i.ibb.co/fHSj7tS/k-Z5y029nc-Zjbk-OZDo-QIh-BOKgdx-LGy-Py-Ekxkt-DUox-Mnjf-Hc-OBOc5-FVw-LVu-Ncuc-AVy-G8-E-w1200-h630-p.png');
          background-image: url('./img2.png');
  
          background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          font-family: 'Noto Sans', sans-serif;
          
          display: flex;
          justify-content: center;
          align-items: center;
    
      }
    
      
  #div2{
    
margin-left: 20%;
width:60%;
align-items: center;
text-align: center;

    h1{
        text-align: center;
        font-size:5em;
        color:black;
        font-weight: bolder;
    }

    p{
      font-weight: 400;
      font-weight: bolder;
    color:grey;
        font-size:1.6em;
    }
    #btn{
        text-align: center;
        background-color:black;
        color:white;
        border-radius:20px;
        font-size:1.2em;
        margin-left: auto;
        margin-right: auto;
        padding:3%;

    }
}
      #div2img{
        margin-top:30vh;
        
        text-align: center;
        margin-left:auto;
    margin-right:auto;
    margin-left: 10%;;
    max-width:100%;
    
    }

    }
    }
    
    

    
@media screen and (max-width:576px) {
    #educationSec{
    
        .div1{
            height:100vh;
            background-color: black;;
            padding-bottom: 8%;;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('./phonebg1.png');
background-size: cover;
background-repeat:no-repeat ;
display: flex;
justify-content: center;
align-items: center;

            
    width:100vw;
            #nesteddiv1{
    margin-top:15vh;
    
    width:100%;
    text-align: left;;
                h1{
                    font-size:1.5em;
                    color:white;
                    margin-left:5%;
            
                    span{
                        color:#1DDAF2;
                    }
                }
    
                h2{
                    font-size:2.8em;
                    font-weight:600;
                    margin-left:5%;
            
                    font-family: 'Montserrat', sans-serif;
                    color:white;
                }
                p{
                    font-size:0.9em;
                    color:white;
     
     display:none;
                }
            }
        }
        #img1{
            display: none;
        }
        #phonep{
            color: white;
            font-size:1.2em;
            text-align: left;;
            margin-left:5%;

            margin-top:0vh;
        }
    #img2{
        
        
        
        width:80%;
    }



    
    #row2{
      
        height:100vh;
        width:100vw;
          background-image: url('./phonebg2.png');
          background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          font-family: 'Noto Sans', sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
    padding-top:auto;
    padding-bottom: auto;;
    vertical-align: middle;
    
      }
 


      #div2{
        margin-top:auto;
        margin-bottom: auto;
        vertical-align: middle;
        
    margin-left:10%;
    width:80%;
    align-items: center;
    text-align: center;
        h1{
            text-align: center;
            font-size:3em;
            color:black;
            font-weight: bolder;
        }
    
        p{
          font-weight: 400;
          font-weight: bolder;
        color:grey;
            font-size:1.2em;
        }
        #btn{
            text-align: center;
            background-color:black;
            color:white;
            border-radius:20px;
            font-size:1em;
            margin-left: auto;
            margin-right: auto;
            padding:5%;
    
        }
    }

      #div2img{
        margin-top:2vh;
        
        text-align: center;
        margin-left:auto;
    margin-right:auto;
    margin-left: 25%;;
        width:50%;
}


        
    }
    }
    
    
    

    

@media (min-width: 998px) {

    #datalogger{
        min-height:110vh;
        margin-top:5%;
        font-family: 'Noto Sans JP', sans-serif;
        background-color: black;;
padding-bottom:10%;
min-height:110vh;


     .beacon_img{
            width:100%;
        }
        #img2{
            display: none;
        }
        #btn2{
            display: none;
        }

        .beacon_img{
            width: 90%;
            margin-top:10%;
            float:right;
        
            position: relative;
            
            animation-direction: alternate;
          }
          
          @keyframes myfirst {
            0%   { top: 0px;}
          
            50%  {top: 20px;}
          
            100% {top: 0px;}
          }
    
        .div{
            
            overflow-x: visible;
            margin-top:20vh;
            text-align:left;
width:90%;
            font-family: 'Noto Sans JP', sans-serif;
            color:white;
       h1{
           font-size: 5em;
           font-family: 'Noto Sans JP', sans-serif;
           span{
               color:#1DDAF2;
               
           }
           font-weight: 600;
               
       }
       p{
        font-family: 'Noto Sans JP', sans-serif;
       
           font-size: 1.5em;;
           float:left;
       }
       button{
           width:30%;
           color:white;
           border-color: white;;
           margin-top:1%;
           font-size: 1.5em;;
           padding:10px;
           
       font-weight: bold;;
       border-radius: 20px;
       
       width:30%;
       color:white;
       border-color: white;;
       font-size: 1.2em;;
   font-weight: bold;;
   border-radius: 25px;
   
        }
        button:hover{
            border-color: black;;
            color:black;
            background-color: white;
        }
#button1{
    background-color: white;
    color:black;
}
#button2{
margin-left:2%;
    background-color: black;
    color:white;
    border:2px solid white;
}
.fa-angle-down{
    color: white;
    font-size: 2em;
    width:100%;
    text-align: center;
}


    }

    .fa-angle-down{
        color: white;
        font-size: 2em;
        width:100%;
        text-align: center;
    }


    }
    }
    
    
    
    
    @media (min-width: 576px) and (max-width: 998px) {
    
        #datalogger{
            
            margin-top:1%;
    
            background-color: black;;
            padding-bottom:10%;
min-height:100vh;

            #img2{
                display: none;
            }
            #btn2{
                display: none;
            }
         .beacon_img{
                width:100%;
            }
    
            .beacon_img{
                width: 100%;
                float:right;
               
                position: relative;
                
                animation-direction: alternate;
              }
              
              @keyframes myfirst {
                0%   { top: 0px;}
              
                50%  {top: 30px;}
              
                100% {top: 0px;}
              }
        
            .div{
     
                overflow-x: visible;
                margin-top:30%;
                text-align:left;
                color:white;
           h1{
               font-size: 4em;
               span{
                color:#1DDAF2;
                
            }
            font-weight: 600;
                
           }
           p{
               font-size: 1.4em;;
               float:left;
           }
           button{
               width:40%;
               color:white;
               border-color: white;;
               margin-top:5%;
               font-size: 1.2em;;
               padding:10px;
               
           font-weight: bold;;
           border-radius: 20px;

            }
            button:hover{
                border-color: black;;
                color:black;
                background-color: white;
            }
            #button1{
                background-color: white;
                color:black;
            }
            #button2{
            margin-left:2%;
                background-color: black;
                color:white;
                border:2px solid white;
            }
    
    
        }

        .fa-angle-down{
            color: white;
            font-size: 2em;
            width:100%;
            text-align: center;
        }
        }
        
    
    
    
    
    }
    
    
    
    @media (max-width: 576px) {
    
        #datalogger{
            padding-bottom:10%;
min-height:110vh;

    margin-top:2%;
            background-color: black;;
    
         .beacon_img{
                width:100%;
            }
    
            .beacon_img{
                width: 100%;
                
              
                position: relative;
                
                animation-direction: alternate;
              }
              #img1{
                  display: none;
              }
              #btn1{
                  display: none;
              }
              #img2{
                display: block;
            }
            #btn2{
                display: block;
                margin-top:10%;
            }
              @keyframes myfirst {
                0%   { top: 0px;}
              
                50%  {top: 10px;}
              
                100% {top: 0px;}
              }
        
            .div{
            width:100%;
                overflow-x: visible;
                margin-top:10%;
                text-align:center;
                color:white;
           h1{
               font-size: 3em;
               span{
                color:#1DDAF2;
                
            }
            font-weight: 900;
                
           }
           p{
               font-size: 1em;;
               text-align: center;
           }
           button{
               width:40%;
               color:white;
               border-color: white;;
               margin-top:1%;
               font-size: 0.8em;;
           font-weight: bold;;
           padding-left:10px;
           padding-right:10px;
           padding-top:10px;
        padding-bottom:10px;
        
           border-radius: 20px;
            }
            button:hover{
                border-color: black;;
                color:black;
                background-color: white;
            }
    
    
        }
        #btn2{
        #button1{
            background-color: white;
            color:black;
            font-size:1em;
            border-radius: 10px;;
            
        }
        #button2{
        margin-left:2%;
            background-color: black;
            color:white;
            border:2px solid white;
            font-size:1em;
            border-radius: 10px;;

        }
    }

    .fa-angle-down{
        color: white;
        font-size: 2em;
        width:100%;
        text-align: center;
        margin-top:5vh;
    }
        }
    
    
    
    }
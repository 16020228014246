         #education{
            font-family: 'Noto Sans JP', sans-serif;
       
         }
@media (min-width: 998px) {

    #education{
        min-height:100vh;
        padding-bottom:10%;
        font-family: 'Noto Sans JP', sans-serif;
          
     .beacon_img{
        max-width:100%;
            height:70vh;
            margin-top:20vh;
           
        }
        #btn2{
            display: none;
        }
        .div{
            margin-left:15%;
            width:100%;
         
            margin-top:30vh;
            text-align:left;

       h1{
           font-size: 3em;
           font-family: 'Noto Sans JP', sans-serif;
           font-weight: 600;
           span{
               color:#1EDAF2;
           }
       }
       p{
           font-size: 1.5em;;
           font-weight: 400;
         width:90%;
           float:left;
       }
       button{
           width:40%;
           font-size: 2.5em;;
       font-weight: bold;;
       border-radius: 10px;
        }
        button{
            width:30%;
            font-size: 1.2em;;
        font-weight: bold;;
        border-radius: 20px;
        padding-left:10px;
        padding-right:10px;
        padding-top:10px;
     padding-bottom:10px;
     }
     
     #button1{
         background-color:black;
         color:white;
         border:0px solid black;
         box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
     }
     
         #button2{
             margin-left:2%;
          background-color:white;
          color:black;
           border:2px solid black;
         }
    }
    .fa-angle-down{
        color: black;
        font-size: 2em;
        width:100%;
        text-align: center;
        margin-top:5vh;
    }

    }
    }
    
    
    
    
    @media (min-width: 576px) and (max-width: 998px) {
    
        #education{
            
            min-height:100vh;
            padding-bottom:10%;
          
         .beacon_img{
                width:100%;
                height:70vh;
                margin-top:20vh;
            }
            #btn2{
                display: none;
            }
            .div{
                margin-left:10%;
                overflow-x: visible;
                margin-top:30%;
                text-align:right;
           h1{
               font-size: 3em;
      
               font-family: 'Noto Sans JP', sans-serif;
               font-weight: 600;
               span{
                   color:#1EDAF2;
               }
           }
           p{
               font-size: 1.3em;;
               float:right;
           }
           button{
               width:80%;
               font-size: 1.2em;;
           font-weight: bold;;
           border-radius: 10px;
            }
            button{
                width:40%;
                font-size: 1.2em;;
            font-weight: bold;;
            border-radius: 20px;
            padding-left:10px;
            padding-right:10px;
            padding-top:10px;
         padding-bottom:10px;
         }
         
         #button1{
             background-color:black;
             color:white;
             border:0px solid black;
             box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
         }
         
             #button2{
                 margin-left:2%;
              background-color:white;
              color:black;
               border:2px solid black;
             }
        }
        .fa-angle-down{
            color: black;
            font-size: 2em;
            width:100%;
            text-align: center;
            margin-top:5vh;
        }
        
        }
        
    
    
    
    
    }
    
    
    
    @media (max-width: 576px) {
    
        #education{
            min-height:100vh;
            padding-bottom:10%;
          
            ;
         .beacon_img{
                width:90%;
            margin-left: 10%;;
            }
            #btn1{
                display: none;
            }
            #btn2{
                display: block;
                width:100%;
                margin-top:10%;
                
            }
            
            .div{
        
                margin-top:10%;
        padding-left:5%;
        padding-right:5%;
                width:100%;
            text-align:center;;
    
           h1{
               font-size:2.5em;
             
               font-family: 'Noto Sans JP', sans-serif;
               font-weight: 900;
               span{
                   color:#1EDAF2;
               }
               text-align:center;
            
            }
           p{
               font-size: 1em;;
               text-align:center;
            
               margin-left: auto;
               margin-right: auto;
              
           }
           button{
               width:80%;
               font-size: 1.3em;;
           font-weight: bold;;
           border-radius: 10px;
           margin-left: auto;
           margin-right: auto;
            }
        }
        button{
            width:45%;
            font-size: 0.8em;;
        font-weight: bold;;
        border-radius: 20px;
        padding-left:10px;
        padding-right:10px;
        padding-top:10px;
     padding-bottom:10px;

     }
     
     #button1{
         margin-left:3%;
    
         background-color:black;
         color:white;
         border:0px solid black;
         box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
         font-size:1em;
         border-radius: 10px;;
     }
     
         #button2{
             margin-left:2%;
          background-color:white;
          color:black;
           border:2px solid black;
           font-size:1em;
           border-radius: 10px;;
         }
     

         .fa-angle-down{
            color: black;
            font-size: 2em;
            width:100%;
            text-align: center;
            margin-top:5vh;
        }
        
        }
        
    
    
    
    
    }

@media screen  and (min-width:996px){


#blog_page{
    font-family: 'Noto Sans', sans-serif;

    background-image: url('./bg.png');
    background-repeat: no-repeat;
background-size: cover;
    min-height:100vh;
    padding-top:20vh;
    padding-bottom:20vh;

#heading{
    h1{
        font-size:3em;
        text-decoration: underline;
        text-underline-offset: 12px;
        text-align:left;
        text-decoration-color: #9A9A9A;
    }
}


#box{
    margin-top:10%;
    border-radius: 20px;;
    background-color: white;
    width:90%;
    border:0px solid red;
    box-shadow: 1px 4px 24px 0px #00000033;

    img{
        width:100%; border-top-left-radius: 20px;;
        border-top-right-radius: 20px;
    }

  div{background-color: white;
      margin:8%;
      
      overflow-y: auto;

      text-align: justify;
      h1{
          font-size:1.3em;
          text-align: left;
          font-weight: 600;
          height:11vh;
          overflow-y: auto;
          
      }
      p{
          font-size:1em;
     
          
      }

      h1::-webkit-scrollbar {
        width: 3px;               /* width of the entire scrollbar */
      }
      
      h1::-webkit-scrollbar-track {
        background:none;       /* color of the tracking area */
      }
        h1::-webkit-scrollbar-thumb {
            background-color: grey;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 1px solid grey;  /* creates padding around scroll thumb */
          }









  
  button{
    text-align: left;
   
    background-color: #7fecfa;
    color:black;
    border:0px solid red;
    margin-bottom: 8%;
    
         }
        }

}


}


}
















@media screen  and (min-width:576px) and (max-width:996px){


    #blog_page{
        font-family: 'Noto Sans', sans-serif;
    
        background-image: url('./bg.png');
        background-repeat: no-repeat;
    background-size: cover;
        min-height:100vh;
        padding-top:20vh;
        padding-bottom:20vh;
    
    #heading{
        h1{
            font-size:2em;
            text-decoration: underline;
            text-underline-offset: 12px;
            text-align:left;
            text-decoration-color: #9A9A9A;
        }
    }
    
    
    #box{
        margin-top:10%;
        border-radius: 20px;;
        background-color: white;
        width:100%;
        border:0px solid red;
        box-shadow: 1px 4px 24px 0px #00000033;
    
        img{
            width:100%; border-top-left-radius: 20px;;
            border-top-right-radius: 20px;
   
        }
    
      div{background-color: white;
          margin:8%;
          
          overflow-y: auto;
    
          text-align: justify;
          h1{
              font-size:1.1em;
              text-align: left;
              font-weight: 600;
              height:15vh;
              overflow-y: auto;
          }
          p{
              font-size:1em;
         
              
          }
    
          h1::-webkit-scrollbar {
            width: 3px;               /* width of the entire scrollbar */
          }
          
          h1::-webkit-scrollbar-track {
            background:none;       /* color of the tracking area */
          }
            h1::-webkit-scrollbar-thumb {
                background-color: grey;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
                border: 1px solid grey;  /* creates padding around scroll thumb */
              }
    
    
    
    
    
    
    
    
    
      
      button{
        text-align: left;
       
        background-color: #7fecfa;
        color:black;
        border:0px solid red;
        margin-bottom: 8%;
        
             }
            }
    
    }
    
    
    }
    
    
    }
















    @media screen  and (max-width:576px){


        #blog_page{
            font-family: 'Noto Sans', sans-serif;
        
            background-image: url('./bg.png');
            background-repeat: no-repeat;
        background-size: cover;
            min-height:100vh;
            padding-top:13vh;
            padding-bottom:20vh;
        
        #heading{
            h1{
                font-size:1.5em;
                text-decoration: underline;
                text-underline-offset: 12px;
                text-align:left;
                text-decoration-color: #9A9A9A;
            }
        }
        
        
        #box{
            margin-top:10%;
            border-radius: 20px;;
            background-color: white;
            width:100%;
            border:0px solid red;
            box-shadow: 1px 4px 24px 0px #00000033;
        
            img{
                width:100%; border-top-left-radius: 20px;;
                border-top-right-radius: 20px;
            }
        
          div{background-color: white;
              margin:8%;
              
              overflow-y: auto;
        
              text-align: justify;
              h1{
                  font-size:1.3em;
                  text-align: left;
                  font-weight: 600;
     
                  
              }
              p{
                  font-size:1em;
             
                  
              }
        
              h1::-webkit-scrollbar {
                width: 3px;               /* width of the entire scrollbar */
              }
              
              h1::-webkit-scrollbar-track {
                background:none;       /* color of the tracking area */
              }
                h1::-webkit-scrollbar-thumb {
                    background-color: grey;    /* color of the scroll thumb */
                    border-radius: 20px;       /* roundness of the scroll thumb */
                    border: 1px solid grey;  /* creates padding around scroll thumb */
                  }
        
        
        
        
        
        
        
        
        
          
          button{
            text-align: left;
           
            background-color: #7fecfa;
            color:black;
            border:0px solid red;
            margin-bottom: 8%;
            
                 }
                }
        
        }
        
        
        }
        
        
        }
        
@media  screen and (min-width:996px) {
    

    #founder{
     padding-bottom:10vh;
        background-image:url('./bg2.png');
        background-size:cover;
        background-repeat: no-repeat;
 
        padding-top:10vh;
    .row{
        width:80%;
        margin-left:10%;
    
    }
    #heading{
        text-align:left;
    margin-left:5%;
        h1{
            font-size:3em;
  
    background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
            font-weight:bold;
         
        }
        margin-bottom:10vh;
    }
    
    #box{
        img{
            width:90%;
            margin-bottom:5%;border-radius:25px;
            transition:1s;
        }
  
        h2{
            font-weight:bold;
           
           color: #407BFF;

   
                    font-weight:bold;
                 
    font-size: 1.5em;;
        }
        p{
            font-size:1em;
        }

table{
width:80%;
margin-left:10%;



    .fa {

        font-size: 30px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
      transition:0.5s;
        margin: 5px 2px;
        border-radius:15px;
      }
      .fa:hover {
          text-decoration: none;
        opacity: 0.7;
    }
    .fa-facebook {
        background: #3B5998;
        color: white;
      }
      
      .fa-twitter {
        background: #55ACEE;
        color: white;
      }
      .fa-linkedin {
        background: #55ACEE;
        color: white;
      }
      .fa-instagram {
        background: #dd4b39;
        color: white;
      }

    
}



    
    }
    
    
    
    
    }
    }
    
    
    @media  screen and  (min-width:576px) and (max-width:996px) {
        
    
        #founder{
            padding-bottom:10vh;
               background-image:url('./bg2.png');
               background-size:cover;
               background-repeat: no-repeat;
        
               padding-top:10vh;
           .row{
               width:100%;
               
           
           }
           #heading{
               text-align:left;
           margin-left:5%;
               h1{
                   font-size:2.5em;
         
           background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
       
           -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
                   font-weight:bold;
                
               }
               margin-bottom:10vh;
           }
           
           #box{
               img{
                   width:90%;
                   margin-bottom:5%;border-radius:25px;
                   transition:1s;
               }
           
               h2{
                   font-weight:bold;
                  
                  color: #407BFF;
       
          
                           font-weight:bold;
                        
           font-size: 1.3em;;
               }
               p{
                   font-size:1em;
               }
       
       table{
       width:80%;
       margin-left:10%;
       
       
       
           .fa {
       
               font-size: 1.2em;
               width: 40px;
               display: flex;
               justify-content: center;
               align-items: center;
               height: 40px;
             transition:0.5s;
               margin: 5px 2px;
               border-radius:15px;
             }
             .fa:hover {
                 text-decoration: none;
               opacity: 0.7;
           }
           .fa-facebook {
               background: #3B5998;
               color: white;
             }
             
             .fa-twitter {
               background: #55ACEE;
               color: white;
             }
             .fa-instagram {
               background: #dd4b39;
               color: white;
             }
             .fa-linkedin {
                background: #dd4b39;
                color: white;
              }
              .fa-linkedin {
                background: #55ACEE;
                color: white;
              }
              .fa-instagram {
                background: #dd4b39;
                color: white;
              }
              
           
       }
       
       
       
           
           }
           
           
           
           
           }
        }
        
        
        
    
    
    
    @media  screen and (max-width:576px) {
        
    
   
    
            #founder{
                padding-bottom:10vh;
                   background-image:url('./bg2.png');
                   background-size:cover;
                   background-repeat: no-repeat;
            
                   padding-top:10vh;
               .row{
                   width:100vw;
                   
               
               }
               #heading{
                   text-align:center;
             
                   h1{
                       font-size:2em;
             
               background: linear-gradient(96.07deg, #407BFF -3.95%, #1DDAF2 105.23%);
           
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
                       font-weight:bold;
                    
                   }
                   margin-bottom:5vh;
               }
               
               #box{
                   width:80%;
                   margin-left:10%;
                   margin-top:10%;

                   img{
                       
                       width:100%;
                       margin-bottom:5%;border-radius:25px;
                       transition:1s;
                   }
         
                   h2{
                       font-weight:bold;
                      
                      color: #407BFF;
           
              
                               font-weight:bold;
                            
               font-size: 1.5em;;
                   }
                   p{
                       font-size:1em;
                   }
           
           table{
           margin-left:auto;
           margin-right: auto;
           
           
           
               .fa {
           
                   font-size: 1.5em;
                   width: 50px;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   height: 50px;
                 transition:0.5s;
                   margin: 2px 2px;
                   border-radius:15px;
                 }
                 .fa:hover {
                     text-decoration: none;
                   opacity: 0.7;
               }
               .fa-facebook {
                   background: #3B5998;
                   color: white;
                 }
                 
                 .fa-twitter {
                   background: #55ACEE;
                   color: white;
                 }
                 .fa-instagram {
                   background: #dd4b39;
                   color: white;
                 }
                 .fa-linkedin {
                    background: #dd4b39;
                    color: white;
                  }
                  .fa-linkedin {
                    background: #55ACEE;
                    color: white;
                  }
                  .fa-instagram {
                    background: #dd4b39;
                    color: white;
                  }
          
               
           }
           
           
           
               
               }
               
               
               
               
               }

            }
            

@media (min-width: 998px) {

    #Beacon2{
        height:110vh;
        
        font-family: 'Noto Sans JP', sans-serif;
        background-color: black;;

     .beacon_img{
            width:100%;
        }
        #img2{
            display: none;
        }
        #btn2{
            display: none;
        }

        #beaconimg1{
            width: 90%;
            
            float:right;
        
            position: relative;
            margin-top:32vh;
            
            animation-direction: alternate;
          }
          
          @keyframes myfirst {
            0%   { top: 0px;}
          
            50%  {top: 20px;}
          
            100% {top: 0px;}
          }
    
        .div{
            
            overflow-x: visible;
            margin-top:35vh;

            text-align:left;
            float:left;
            margin-left: 10%;
width:100%;
            font-family: 'Noto Sans JP', sans-serif;
            color:white;
            h3{
                font-size: 2.8em;
                font-family: 'Noto Sans JP', sans-serif;
                span{
                    color:#1DDAF2;
                    
                }
                font-weight: 400;
                    
            }
            h2{
                font-size: 3.3em;
                font-family: 'Noto Sans JP', sans-serif;
                span{
                    color:#1DDAF2;
                    
                }
                font-weight: 600;
                    
            }
       h1{
           font-size: 2.8em;
           font-family: 'Noto Sans JP', sans-serif;
           color:#1DDAF2;
           span{
               color:#1DDAF2;
               
           }
           font-weight: 600;
               
       }
       p{
        font-family: 'Noto Sans JP', sans-serif;
       
           font-size: 1.5em;;
           float:left;
       }
       button{
           width:30%;
           color:white;
           border-color: white;;
           margin-top:1%;
           font-size: 1.5em;;
           padding:10px;
           
       font-weight: bold;;
       border-radius: 20px;
       
       width:30%;
       color:white;
       border-color: white;;
       font-size: 1.2em;;
   font-weight: bold;;
   border-radius: 25px;
   
        }
        button:hover{
            border-color: black;;
            color:black;
            background-color: white;
        }
#button1{
    background-color: white;
    color:black;
}
#button2{
margin-left:2%;
    background-color: black;
    color:white;
    border:2px solid white;
}


    }
    margin-top:0px;
    }
    }
    
    
    
    
    @media (min-width: 576px) and (max-width: 998px) {
    
    #Beacon2{
        
            height:100vh;
            margin-top:1%;
    
            background-color: black;;
            #img2{
                display: none;
            }
            #btn2{
                display: none;
            }
         .beacon_img{
                width:100%;
            }
    
        #beaconimg1{
            
                width: 100%;
                float:right;
               margin-top:50%;
                position: relative;
                
                animation-direction: alternate;
              }
              
              @keyframes myfirst {
                0%   { top: 0px;}
              
                50%  {top: 30px;}
              
                100% {top: 0px;}
              }
        
            .div{
     
                overflow-x: visible;
                margin-top:50%;
                text-align:left;
                color:white;
                h3{
                    font-size: 2em;
                    font-family: 'Noto Sans JP', sans-serif;
                    span{
                        color:#1DDAF2;
                        
                    }
                    font-weight: 400;
                        
                }
                h2{
                    font-size: 2.2em;
                    font-family: 'Noto Sans JP', sans-serif;
                    span{
                        color:#1DDAF2;
                        
                    }
                    font-weight: 600;
                        
                }
           h1{
               font-size: 2em;
               font-family: 'Noto Sans JP', sans-serif;
               color:#1DDAF2;
               span{
                   color:#1DDAF2;
                   
               }
               font-weight: 600;
                   
           }
           p{
               font-size: 1.4em;;
               float:left;
           }
           button{
               width:40%;
               color:white;
               border-color: white;;
               margin-top:5%;
               font-size: 1.2em;;
               padding:10px;
               
           font-weight: bold;;
           border-radius: 20px;

            }
            button:hover{
                border-color: black;;
                color:black;
                background-color: white;
            }
            #button1{
                background-color: white;
                color:black;
            }
            #button2{
            margin-left:2%;
                background-color: black;
                color:white;
                border:2px solid white;
            }
    
    
        }
        }
        
    
    
    
    
    }
    
    
    
    @media (max-width: 576px) {
        #Beacon2{
    
            height:100vh;
    margin-top:0%;
            background-color: black;;
    
         .beacon_img{
                width:100%;
            }
    
        #beaconimg1{
            
                width: 100%;
                
              
                position: relative;
                
                animation-direction: alternate;
              }
              #beaconimg1{
                  display: none;
              }
              #btn1{
                  display: none;
              }
              #img2{
                display: block;
            }
            #btn2{
                display: block;
                margin-top:10%;
            }
              @keyframes myfirst {
                0%   { top: 0px;}
              
                50%  {top: 10px;}
              
                100% {top: 0px;}
              }
        
            .div{
            width:100%;
                overflow-x: visible;
                margin-top:20%;
                text-align:left;
                color:white;
                h3{
                    font-size: 1.3em;
                    padding-left:5%;
                    padding-right:5%;
                    

                    font-family: 'Noto Sans JP', sans-serif;
                    span{
                        color:#1DDAF2;
                        
                    }
                    font-weight: 400;
                        
                }
                h2{
                    padding-left:10%;
                    
                    padding-left:5%;
                    padding-right:5%;
                    
                    font-size: 2em;
                    font-family: 'Noto Sans JP', sans-serif;
                    span{
                        color:#1DDAF2;
                        
                    }
                    font-weight: 600;
                        
                }
           h1{
            padding-left:10%;
                    
            padding-left:5%;
            padding-right:5%;
            
               font-size: 1.3em;
               font-family: 'Noto Sans JP', sans-serif;
               color:#1DDAF2;
               span{
                   color:#1DDAF2;
                   
               }
               font-weight: 600;
                   
           }
           p{
               font-size: 1em;;
               text-align: center;
           }
           button{
               width:40%;
               color:white;
               border-color: white;;
               margin-top:1%;
               font-size: 0.8em;;
           font-weight: bold;;
           padding-left:10px;
           padding-right:10px;
           padding-top:10px;
        padding-bottom:10px;
        margin-left:10%;
        
           border-radius: 20px;
            }
            button:hover{
                border-color: black;;
                color:black;
                background-color: white;
            }
    
    
        }

        #button1{
            background-color: white;
            color:black;
            margin-left:25%;;
            width:50%;
border-radius:10px;
font-size:1em;
            
        }
        #button2{
        margin-left:2%;
            background-color: black;
            color:white;
            border:2px solid white;
        }
        }
    
    
    
    }
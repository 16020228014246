@media screen and (min-width:996px){
    

#login{
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom:20%;
    padding-top:1%;
    min-height: 100vh;;
        
border:0px solid red;


.modal{
.successmodal{
    
    h1{
        color:green;
    font-size:2.5em;
    }


}
}
#form{
        width:70%;
        margin-top: 20vh;;
        
        margin-left:15%;
        background-color: white;;
        padding:0%;
        padding-left:0%;
        padding-right:0%;
        border-radius: 25px;
        box-shadow: 3.8px 4.75px 54.15px rgba(0, 0, 0, 0.25);;
    
form{
    width:80%;
    margin-left: 15%;
input{
    padding:6%;        background-color: #E9EEF2;
}
    ::placeholder { /* Firefox, Chrome, Opera */
        color: #AAAFB4;
    }
    .pass-wrapper{
        input{
        padding:6%;
        }
    }

    .btn1{
        background-image: linear-gradient(to right, #1FD9F3,#5BA5FD);
        color:white;width:45%;
        float:left;
    }

.btn2{
    color:black;  border:0px solid red;
}
.btn2:hover
{
    background-color: white;;
    border:0px solid red;
}

table{
    width:100%;
    img:hover{
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

}

}

        #heading{
            width:100%;
            text-align: center;
            margin-bottom:5%;
            margin-left: 15%;
            margin-top:10%;
            align-items: center;
            h1{
                font-size:2em;font-weight: bolder;

            }
            p{
                font-size:1.2em;
                color:grey;
            }
        

        }
        img{
            height:100%;
            width:100%;       border-radius: 25px;

        }

        .pass-wrapper {
            position: relative;
            display: flex;
            
            input{
                padding:5%;border:0px solid red;
        
            }
          }
          
          i {
            position: absolute;
            
       top: 20%;;
            right: 7%;
          }
          i:hover {
            
            cursor: pointer;
          }

    }
}

}



@media screen and (min-width:576px) and (max-width:996px){
    

    #login{
        background-image: url('./bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom:10%;
        min-height: 100vh;;

        padding-top:1%;
    
        #form{
            width:85%;
            margin-top: 15vh;;
            margin-left:7%;
            background-color: white;;
     
            border-radius: 25px;
            box-shadow: 3.8px 4.75px 54.15px rgba(0, 0, 0, 0.25);;
       
form{
    width:90%;
    margin-left: 5%; padding-top:5%;
    margin-top:5%;
input{
    padding:6%;        background-color: #E9EEF2;
}
    ::placeholder { /* Firefox, Chrome, Opera */
        color: #AAAFB4;
    }
    .pass-wrapper{
        input{
        padding:6%;
        }
    }

    .btn1{
        background-image: linear-gradient(to right, #1FD9F3,#5BA5FD);
        color:white;width:30%;
        float:left;
    }

.btn2{
    color:black;  border:0px solid red;
}
.btn2:hover
{
    background-color: white;;
    border:0px solid red;
}

table{
    width:100%;
    img:hover{
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

}

}  #heading{
                width:100%;
                text-align: center;
                margin-bottom:5%;
                margin-left:5%;margin-top:5%;
                align-items: center;
                h1{
                    font-size:1.5em;
    
                }
                p{
                    font-size:1em;
                    color:grey;
                }
            
    
            }
            img{
                height:100%;
                width:100%;       border-radius: 25px;
    
            }
    
            .pass-wrapper {
                position: relative;
                display: flex;
                
                input{
                    padding:5%;border:0px solid red;
            
                }
              }
              
              i {
                position: absolute;
                
           top: 20%;;
                right: 7%;
              }
              i:hover {
                
                cursor: pointer;
              }
    
        }
    }
    
    }



@media screen and (max-width:576px){
    

    #login{
        background-image: url('./bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom:10%;
        min-height: 100vh;;

        padding-top:1%;
    
    #desktop{
        display: none;
    }
        #form{
            width:90%;
            margin-top: 15vh;;
            margin-left:5%;
            background-color: white;;
            padding:5%;
            padding-left:5%;
            padding-right:5%;
            border-radius: 25px;
            box-shadow: 3.8px 4.75px 54.15px rgba(0, 0, 0, 0.25);;
     
            form{
                width:90%;
                margin-left: 5%; padding-top:5%;font-size:1em;
                margin-top:5%;
            input{
                padding:6%;        background-color: #E9EEF2;
            }
                ::placeholder { /* Firefox, Chrome, Opera */
                    color: #AAAFB4;
                }
                .pass-wrapper{
                    input{
                    padding:6%;
                    }
                }
            
                .btn1{
                    background-image: linear-gradient(to right, #1FD9F3,#5BA5FD);
                    color:white;width:30%;font-size:1em;
                    float:left;
                }
            
            .btn2{
                color:black;  border:0px solid red;font-size:1em;
            }
            .btn2:hover
            {
                background-color: white;;font-size:1em;
                border:0px solid red;
            }
            
            table{
                width:100%;
                img{
                    display: block;width:100%;
                }
                img:hover{
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
            
            }
            
            } 
                    #heading{
                        width:100%;
                        text-align: center;
                        margin-bottom:5%;
                        margin-left:5%;
                        align-items: center;
                        h1{
                            font-size:1.5em;
            
                        }      p{
                            font-size:1em;
                            color:grey;
                        }
                    
            
                    }
               
            .pass-wrapper {
                position: relative;
                display: flex;
                
                input{
                    padding:5%;border:0px solid red;
            
                }
              }
              
              i {
                position: absolute;
                
           top: 20%;;
                right: 7%;
              }
              i:hover {
                
                cursor: pointer;
              }
    
        }
    }
    
    }
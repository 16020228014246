@media screen and (min-width:996px) {
    

#accordion{
    text-align:left;
    
    margin-bottom: 10vh;;
    .card-header{
        border:2px solid grey;
        border-radius:25px;

        font-size:1.3em;
padding:20px;;
    }
    .card{
        box-shadow: 5px 5px 10px grey;
        border-radius:25px;
        margin-top:2%;
        margin-bottom:2%;
        
    }
    .btn-header-link:after {
        content: "\f106";
        font-family: 'FontAwesome';
        font-weight: 900;
        top:0px;
        font-weight: bolder;;
        
        float: right;
        
      }
      
       .btn-header-link.collapsed {
        
        font-weight: bolder;;

      }
      
     .btn-header-link.collapsed:after {
        content: "\f107";
        
      }

      
   .card .collapsing {
        
        
        text-align: left;;
        background-color:rgb(235, 232, 232);;


        line-height: 30px;
      }

      .card .collapse.show {
        
        border-radius:25px;
text-align: left;;
        background-color:rgb(235, 232, 232);;


        line-height: 30px;
        color: #222;
      }
}

}


@media screen and (min-width:576px) and (max-width:996px) {
    

    #accordion{
        text-align:left;
        margin-top:10vh;
        margin-bottom: 10vh;;
        .card-header{
            border:2px solid grey;
            border-radius:25px;
    
            font-size:1em;
    padding:20px;;
        }
        .card{
            box-shadow: 5px 5px 10px grey;
            border-radius:25px;
            margin-top:2%;
            margin-bottom:2%;
            width:100%;
            
        }
        .btn-header-link:after {
            content: "\f106";
            font-family: 'FontAwesome';
            font-weight: 900;
            top:0px;
            font-weight: bolder;;
            
            float: right;
            
          }
          
           .btn-header-link.collapsed {
            
            font-weight: bolder;;
    
          }
          
         .btn-header-link.collapsed:after {
            content: "\f107";
            
          }
    
          
       .card .collapsing {
            
            
            text-align: left;;
            background-color:rgb(235, 232, 232);;
    
    
            line-height: 30px;
          }
    
          .card .collapse.show {
            
            border-radius:25px;
    text-align: left;;
            background-color:rgb(235, 232, 232);;
    
    
            line-height: 30px;
            color: #222;
          }
    }
    


    }




    

@media screen and (max-width:576px) {
    

    #accordion{
        text-align:left;
        margin-top:5vh;
        margin-bottom: 10vh;;
        .card-header{
            border:2px solid grey;
            border-radius:25px;
    
            font-size:1em;
    padding:20px;;
        }
        .card{
            box-shadow: 5px 5px 10px grey;
            border-radius:25px;
            margin-top:2%;
            margin-bottom:2%;
            width:100%;
            
        }
        .btn-header-link:after {
            content: "\f106";
            font-family: 'FontAwesome';
            font-weight: 900;
            top:0px;
            font-weight: bolder;;
            
            float: right;
            
          }
          
           .btn-header-link.collapsed {
            
            font-weight: bolder;;
    
          }
          
         .btn-header-link.collapsed:after {
            content: "\f107";
            
          }
    
          
       .card .collapsing {
            
            
            text-align: left;;
            background-color:rgb(235, 232, 232);;
    
    
            line-height: 30px;
          }
    
          .card .collapse.show {
            
            border-radius:25px;
    text-align: left;;
            background-color:rgb(235, 232, 232);;
    
    
            line-height: 30px;
            color: #222;
          }
    }
    
    }

@media screen and (min-width:996px){
    


#applicationsrfid{
    
    background-color: white;;
    background-image: url('./bg3.png');
    background-size:cover;
    background-repeat: no-repeat;
    padding-top:10%;
    padding-bottom:10%;
    

    #applicationsheading{
        align-items: center;
        text-align:center;
width:100%;
margin-bottom:5vh;

        h1{
            font-size:2.5em;
            text-align:center;
        
            span{
                color:#1DDAF2;
            }
        }
        p{
            font-size:1.5em;
        }
    }


    .card{
        background-color:#2C2C2C;

        color:white;

        width:95%;
        padding:2%;
        margin-left: 2%;;
        margin-right:2%;
        border-radius: 25px;;
        height:55vh;
   
        
        #img{
           width:100%;
           height:30vh;

            border-radius: 25px;;

        }
        .carousel_div{
            margin-top:2vh;
            width:95%;
            margin-left:3%;
            overflow-y:auto;
            scrollbar-width: thin;
          
              
            h1{
                font-size:1.5em;

            }
            p{
                font-size:0.9em;
                text-align: left;
                
            }
        }
        .carousel_div::-webkit-scrollbar {
            width: 0.2em;
            color:grey;
            
            background-color: grey;
          }

          .carousel_div::-webkit-scrollbar-track {
            background: rgb(199, 196, 196);        /* color of the tracking area */
          }
          .carousel_div::-webkit-scrollbar-thumb {
                background-color: grey;    /* color of the scroll thumb */
                border-radius: 20px;       /* roundness of the scroll thumb */
                border: 1px solid grey;  /* creates padding around scroll thumb */
              }
    }
  

    .carousel-inner{
        width:80%;
        margin-left:10%;
    }

    .carousel{
        margin-left:5%;
        width:90%;
        margin-top:0%;
        min-height:60vh;
        #img{
            height:10vh;height:30vh;
           
        }

    }
    .carouselphone{
        display:none;

    }
.carousel-control-prev-icon{
color:#2C2C2C;
padding:5%;
        background-color: black;;
        border-radius: 5px;;


}
.carousel-control-next-icon{
    color:#2C2C2C;background-color: black;;
    border-radius: 5px;;
        
        
            
    }
    
}

}




@media screen and (min-width:576px) and (max-width:996px){
    


    #applicationsrfid{
        background-color: white;

        background-size:cover;
        background-repeat: no-repeat;
        padding-top:5%;
        padding-bottom:5%;
        
        height:100vh;
        #applicationsheading{
            width:100vw;
            text-align: center;
            align-items:center;
margin-left:5%;
align-items: center;
text-align:center;
width:100%;
            h1{
                font-size:2em;
                span{
                    color:#1DDAF2;
                }
            }
            p{
                font-size:1em;
            }
        }
    
    
        .card{
            background-color:#2C2C2C;
        color:white;
        
            width:98%;
            padding:1%;
            margin-left: 1%;;
            margin-right:1%;
            border-radius: 25px;;
            height:50vh;
            #img{
               width:100%;
               height:30vh;
    
                border-radius: 25px;;
    
            }
            .carousel_div{
                margin-top:2vh;
                width:95%;
                margin-left:3%;
                overflow-y: scroll;
                h1{
                    font-size:1em;
    
                }
                p{
                    font-size:0.8em;
                    text-align: left;
                }
            }
            .carousel_div::-webkit-scrollbar {
                width: 0.2em;
                color:grey;
                
                background-color: grey;
              }
        }

        ::-webkit-scrollbar-thumb {
            background: orangered;
          }
    
        .carousel-inner{
            width:80%;
            margin-left:10%;
        }
    
        .carousel{
            
            width:100%;
            margin-top:0%;
            height:70vh;
            #img{
                height:10vh;height:30vh;
               
            }
    
        }
        .carouselphone{
            display:none;
    
        }
        .carousel-control-prev-icon{
            color:#2C2C2C;
            padding:5%;
                    background-color: black;;
                    border-radius: 5px;;
            
            
            }
            .carousel-control-next-icon{
                color:#2C2C2C;background-color: black;;
                border-radius: 5px;;
                    
                    
                        
                }
       
    }
    
    }


    @media screen and (max-width:576px){
    


        #applicationsrfid{
            background-color: white;
        
            background-size:cover;
            background-repeat: no-repeat;
            padding-top:5vh;
            padding-bottom:10%;
            
            
            #applicationsheading{
                width:100vw;
                text-align: center;
                align-items:center;
margin-left:5%;
align-items: center;
text-align:center;
width:90%;

                h1{
                    font-size:1.5em;
                    span{
                        color:#1DDAF2;
                    }
                }
                p{
                    width:90%;
                    margin-left:5%;
                    font-size:1em;
                }
            }
        
        
            .card{
                background-color:#2C2C2C;
        color:white;
        
                width:98%;
                padding:1%;
                margin-left: 1%;;
                margin-right:1%;
                border-radius: 25px;;
                height:50vh;
                margin-bottom:5vh;
                #img{
                   width:100%;
                   height:30vh;
        
                    border-radius: 25px;;
        
                }
                .carousel_div{
                    margin-top:2vh;
                    width:95%;
                    margin-left:3%;
                    overflow-y: auto;
                    h1{
                        font-size:1em;
        
                    }
                    p{
                        font-size:0.8em;
                        text-align: left;
                    }
                }
                
            .carousel_div::-webkit-scrollbar {
                width: 0.2em;
                color:grey;
                
                background-color: grey;
              }
              .carousel_div::-webkit-scrollbar-track {
                background: rgb(199, 196, 196);        /* color of the tracking area */
              }
              .carousel_div::-webkit-scrollbar-thumb {
                    background-color: grey;    /* color of the scroll thumb */
                    border-radius: 20px;       /* roundness of the scroll thumb */
                    border: 1px solid grey;  /* creates padding around scroll thumb */
                  }
            }
        
            ::-webkit-scrollbar-thumb {
                background: orangered;
              }
            .carousel-inner{
                width:80%;
                margin-left:10%;
            }
        
            .carousel{
                
                width:100%;
              //  margin-top:25vh;
                
                #img{
                    height:10vh;height:30vh;
                   
                }
             
        
            }
            .carouselphone{
                display:block;
        
            }
            .carouseldesktop{
                display: none;
            }
            
            .carousel-control-prev-icon{
                color:#2C2C2C;
                padding:5%;
                        background-color: black;;
                        border-radius: 5px;;
                
                
                }
                .carousel-control-next-icon{
                    color:#2C2C2C;background-color: black;;
                    border-radius: 5px;;
                        
                        
                            
                    }
        }
        
        }

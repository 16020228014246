@media screen and (min-width:996px){
    
#iotlab_product{
    min-height:100vh;
    padding-bottom:20vh;
padding-top:20vh;

    #input{
        
align-items: left;;
text-align: left;

        input:hover{
            cursor: pointer;
        }        input{
            width:100%;background: #E9E5E4;
            color:black;
            border-radius:5px;
            padding:2%;float:left;
            border:0px solid red;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #9C9C9C;
            text-align: center;
          }
            
    }


.div2{
width: 80%;
margin-left:10%;
margin-top:10vh;

.carousel{
    max-width: 95%;
    border:0px solid red;
    height:40vh;
    border-radius: 30px;;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.carousel-control-prev-icon{
    color:black;
}

.carousel-control-next-icon{

    color:black;
}

.carousel-control-prev{
    color:black;
}

.carousel-control-prev-icon {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230d0d0d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
 
.carousel-control-next-icon {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230d0d0d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators li {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #404040;
}
.carousel-inner{
    

    img{
        height:100%;
        width:90%;
        margin-left:5%;
    
    
        
    }
}
.carousel-indicators{
 margin-top:2vh;
    li{
        background-color: black;
    }
}




.div3{
    text-align: left;
    padding:0px;
    margin:0px;
width:105%; 
margin-left:-5%;

h1{
    font-size: 1.3em;;
    font-weight: bold;;
}
h2{
   width:50%;
    padding:10px;
    font-size:1.3em;
    text-align: center;;
    background-color: #E9E5E4;
    color:black;
   font-weight: bold;;

    border-radius: 5px;;
}
h3{
    font-size:1.5em;
    font-weight:bold;
    
}
p{
    font-size:1.2em;text-align: justify;
}
h4{
    color:black;
    span{
        font-size:0.7em;
        color:grey;
    }


    .checked {
        color: orange;
      }
    }
}



.div4{


    width:90%;

    .row3{
        margin-top:2%;
    
        p{
            text-align: left;
            width:100%;
            font-size:1.2em;
        }
    
        h4{
            
            color:
            #52796F;
            font-size:2.3em;
            text-align: left;;font-weight:bolder;
            span{
                font-size: 0.7em;;
            }
    
        }
    
        .input-group{
            border-radius: 15px;;
            padding-top:2%;
        
            width:100%;
    
            #quantity{
                padding:2px;  width:10px;;;
               text-align: center;font-size:1.1em;
               border-top:1px solid grey;
               border-bottom:1px solid grey;
               
            }
            .button1{
                font-size:1em;border-radius: 15px 0px 0px 15px;;font-size:0.9em; width: 30px;;height:100%;  border:1px solid grey;
            }
            .button2{
                font-size:1em;border-radius: 0px 15px 15px 0px;;font-size:0.9em;height:100%;  border:1px solid grey;
                width: 30px;;
                margin-top:0px;
            }
            
        }
        
        .card_button{
          color:red;
        }
        .button1{
            color:black;
            padding:4%;
            width:100%;
            background: #1DDAF2;border-radius: 10px;;
            border:0px solid red;
            font-size: 1.1em;;
            font-weight: bolder;
        }
        .button2{
            color:black;
            background: #BEF8FF;
            padding:4%;
    font-size:1em;
    border:0px solid red;
    border-radius: 10px;;
            width:100%;
            font-size: 1.1em;;
            font-weight: bolder;
            margin-top:10px;
    
        }
        .button3{     color:black;
            padding:4%;
    font-size:1em;
    border:2px solid #58bdc9;
    border-radius: 10px;;
            width:100%;
    background: white;
            margin-top:10px;
            font-size: 1.1em;;
            font-weight: bolder;
    
        }
    }
    


}



#heading{
    margin-top:5vh;
    
    text-align:left;
    h1{
        font-size:2em;
        font-weight: bold;
        color:black;
    }
    p{
        text-align: justify;
        font-size:1.3em;
    }
}


#table{
    margin-top:5vh;
    text-align: left;
    h1{
        font-size:2em;
        font-weight: bold;
    }

table{
    font-size:1em;
  
    
    border-collapse:separate;

   
    
    width:100%;
    td{
border:1px solid grey;
border-bottom: 1px solid grey;
    
    }
    tr:hover td{
        background-color: #E9E5E4;
        
        border-radius: 0px;;
    }
    
td{
    padding:10px;
         transition: 0.2s;
    border-radius: 0px;;
}




#left{
    background-color: #E9E5E4;
    color:black;
    width:30%;
    
    
}
#left:hover{
    background-color: rgb(230, 81, 81);;
color:white
}


}



}


.div5{
    margin-top:10vh;
    h1{
        text-align: left;
        color:black;
        font-size:2em;
        font-weight: bold;;
    }
    
.card{ box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top:5vh;
    width:85%;
    margin-left:7%;
    padding:5%;;
    text-align: left;
    border-radius: 20px;;
    table{
        #img{
            width:25%;
        }
        img{
        
        border-radius: 50%;;
        border:1px solid grey;
            width:80%;;
        }
    
        h1{
            width:80%;
            font-size:1.5em;
    
        }
    }
        p{
            font-size: 1.1em;;
            .checked{
                color:orange;
            }
        }
        h4{
            font-size:0.9em;
        }
 h5{
     color:grey;
     font-size:0.8em;
 }
 
   
}

               } 


               .review_form{
                margin-top: 10vh;;
text-align: left;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
padding: 5%;;
border-radius: 30px;;
                h1{
                    font-size:2em;
                    font-weight: bold;;
                }

                form{
                    margin-top:3vh;

                    div{
                        margin-top:5px;
                    }
                    label{
                        font-size:1.2em;
                    }
                    input , textarea{
                        font-size:1em;    background-color: #F3F3F3; border:0px solid red;
                    }
                    button{
                        margin-left:auto;
                        margin-right: auto;
                        text-align: center;
                        background-color: #1DDAF2;
                        color:black;
                        border:0px solid red;
                    }
                    .form-control{
                        font-size:1em;
                    }
                    .rating {
                        display: inline-block;
                        position: relative;
                        height: 30px;
                        line-height: 30px;
                        font-size: 30px;
                      }
                      
                      #star{
                          margin-top:-20px;
                          top:0px;
                          
                      }
                      .rating label {
                       
                        position: absolute;
                
                        
                        left: 0;
                        height: 100%;
                        cursor: pointer;
                      }
                      
                      .rating label:last-child {
                        position: static;    
                      }
                      
                      .rating label:nth-child(1) {
                        z-index: 5;    
                      }
                      
                      .rating label:nth-child(2) {
                        z-index: 4;    
                      }
                      
                      .rating label:nth-child(3) {
                        z-index: 3;    
                      }
                      
                      .rating label:nth-child(4) {
                        z-index: 2;    
                      }
                      
                      .rating label:nth-child(5) {
                        z-index: 1;     
                      }
                      
                      .rating label input {
                        position: absolute;
                      
                        top: 0;
                        left: 0;
                        opacity: 0;
                      }
                      
                      .rating label .icon {
                        float: left;
                        color: transparent;    
                      }
                      
                      .rating label:last-child .icon {
                        color: #000;    
                      }
                      
                      .rating:not(:hover) label input:checked ~ .icon,
                      .rating:hover label:hover input ~ .icon {
                        color: orange;    
                      }
                      
                      .rating label input:focus:not(:checked) ~ .icon:last-child {
                        color: #000;     top: 10px;
                        text-shadow: 0 0 5px #09f;
                      }

                }
            }
            





            .div6{
                margin-top:10vh;
                h1{
                    text-align: left;
                    color:black;
                    font-size:2em;
                    font-weight: bold;;
                }
                p{ font-size:1.2em;
                    text-align: left;
                    color: grey;;
                }

            }


}

#table{
table{
    border:1px solid grey;
}
}

}
}














@media screen and (min-width:576px) and (max-width:996px) {
    
    #iotlab_product{
        min-height:100vh;
        padding-bottom:20vh;
    padding-top:20vh;
    
        #input{
            margin-left:auto;
            margin-right: auto;
            input{
                width:100%;background: #E9E5E4;
                color:black;
                border-radius:5px;
                padding:2%;
                border:0px solid red;
            }
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #9C9C9C;
                text-align: center;
              }
                
        }
    



        .div2{
            width: 100%;
            
            margin-top:10vh;
            
            .carousel{
                max-width: 90%;
                border:0px solid red;
                height:30vh;    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
            
       

            .carousel-control-prev-icon {
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230d0d0d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
            }
             
            .carousel-control-next-icon {
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230d0d0d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
            }
           
            .carousel-indicators li {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                background-color: #404040;
            }
            
            .carousel-inner{
                
                img{
                    width:100%;
                    height:100%;
                }
            }

 
            .carousel-indicators{
             
                li{
                    background-color: black;
                }
            }
            
            
            
            
            .div3{
                text-align: left;
            h1{
                font-size: 1.1em;;
            }
            h2{
                min-width:20%;
                font-size:0.8em;
                text-align: center;;
                background-color: #E9E5E4;
                color:black;
                padding:4px;
            
                border-radius: 5px;;
            }
            h3{
                font-size:1em;
                font-weight:bolder;
                
            }
            p{
                font-size:0.9em;text-align: justify;
            }
            h4{
                color:black;
                span{
                    font-size:0.5em;
                    color:grey;
                }
            
            
                .checked {
                    color: orange;
                  }
                }
            }
            
            
            
            .div4{
            
            
            
                .row3{
                    margin-top:2%;
                
                    p{
                        text-align: left;
                        width:100%;
                        font-size:0.9em;
                    }
                
                    h4{
                        
                        color:
                        #52796F;
                        font-size:1.3em;
                        text-align: left;;
                        span{
                            font-size: 0.6em;;
                        }
                
                    }
                
                    .input-group{
                        border-radius: 15px;;
                        padding-top:2%;
                    
                        width:100%;
                 
                        #quantity{
                            padding:2px;  width:10px;;;
                           text-align: center;font-size:0.9em;
                           border-top:1px solid grey;
                           border-bottom:1px solid grey;
                           
                        }
                        .button1{
                            font-size:0.8em;border-radius: 15px 0px 0px 15px;;font-size:0.9em; width: 30px;;height:100%;  border:1px solid grey;
                        }
                        .button2{
                            font-size:0.8em;border-radius: 0px 15px 15px 0px;;font-size:0.9em;height:100%;  border:1px solid grey;
                            width: 30px;;
                            margin-top:0px;
                        }
                        
                    }
                    
                    .card_button{
                      color:red;
                    }
                    .button1{
                        color:black;
                        padding:9%;        font-size:0.8em;
                        width:100%;
                        background: #1DDAF2;border-radius: 10px;;
                        border:0px solid red;
                    }
                    .button2{
                        color:black;
                        background: #BEF8FF;
                        padding:4%;
                font-size:0.8em;
                border:0px solid red;
                border-radius: 10px;;
                        width:100%;
                 
                        margin-top:10px;
                
                    }
                    .button3{     color:black;
                        padding:4%;
                font-size:0.8em;
                border:2px solid #58bdc9;
                border-radius: 10px;;
                        width:100%;
                background: white;
                        margin-top:10px;
                
                    }
                }
                
            
            
            }
            
            #heading{
                margin-top:10vh;
                
                text-align:left;
                h1{
                    font-size:1.1em;
                    font-weight: bolder;
                    color:black;
                }
                p{
                    text-align: justify;
                    font-size:1em;
                }
            }
            
            
            
            
            #table{
                margin-top:10vh;
                text-align: left;
                h1{
                    font-size:1.3em;
                }
            
            table{
                font-size:1em;
                border-radius: 20px;;
                border-collapse: collapse;
                border:1px solid black;
                width:100%;
                tr td{
            border:1px solid black;
                }
                tr:hover td{
                    background-color: #E9E5E4;
                }
            td{
                padding:10px;
            }
            
            #left{
                background-color: #E9E5E4;
                color:black;
            }
            #left:hover{
                background-color: white;;
            }
            
            }
            
            
            }
            
             
            .div5{
                margin-top:10vh;
                h1{
                    text-align: left;
                    color:black;
                    font-size:1.5em;
                    font-weight: bolder;;
                }
                
            .card{ box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                margin-top:5vh;
                width:90%;
                margin-left:5%;
                padding:10px;
                text-align: left;
                table{
                    #img{
                        width:25%;
                    }
                    img{
                    
                    border-radius: 50%;;
                    border:1px solid black;
                        width:80%;;
                    }
                
                    h1{
                        width:80%;
                        font-size:1.5em;
                
                    }
                }
                    p{
                        font-size: 1.1em;;
                        .checked{
                            color:orange;
                        }
                    }
                    h4{
                        font-size:0.9em;
                    }
             h5{
                 color:grey;
                 font-size:0.8em;
             }
             
               
            }
            
                           }             
            
            
            
            
            
                           .review_form{
                            margin-top: 10vh;;
            text-align: left;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
padding: 5%;;
                            h1{
                                font-size:1.5em;
                                font-weight: bolder;;
                            }
            
                            form{
                                margin-top:5vh;
            
                                div{
                                    margin-top:5px;
                                }
                                label{
                                    font-size:1em;
                                }
                                input , textarea{
                                    font-size:1em;    background-color: #E9E5E4; border:0px solid red;
                                }
                                button{
                                    margin-left:auto;
                                    margin-right: auto;
                                    text-align: center;
                                    background-color: #1DDAF2;
                                    color:black;
                                    border:0px solid red;
                                }
                                .form-control{
                                    font-size:1em;
                                }
                                .rating {
                                    display: inline-block;
                                    position: relative;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 30px;
                                  }
                                  
                                  #star{
                                      margin-top:-20px;
                                      top:0px;
                                      
                                  }
                                  .rating label {
                                   
                                    position: absolute;
                            
                                    
                                    left: 0;
                                    height: 100%;
                                    cursor: pointer;
                                  }
                                  
                                  .rating label:last-child {
                                    position: static;    
                                  }
                                  
                                  .rating label:nth-child(1) {
                                    z-index: 5;    
                                  }
                                  
                                  .rating label:nth-child(2) {
                                    z-index: 4;    
                                  }
                                  
                                  .rating label:nth-child(3) {
                                    z-index: 3;    
                                  }
                                  
                                  .rating label:nth-child(4) {
                                    z-index: 2;    
                                  }
                                  
                                  .rating label:nth-child(5) {
                                    z-index: 1;     
                                  }
                                  
                                  .rating label input {
                                    position: absolute;
                                  
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                  }
                                  
                                  .rating label .icon {
                                    float: left;
                                    color: transparent;    
                                  }
                                  
                                  .rating label:last-child .icon {
                                    color: #000;    
                                  }
                                  
                                  .rating:not(:hover) label input:checked ~ .icon,
                                  .rating:hover label:hover input ~ .icon {
                                    color: orange;    
                                  }
                                  
                                  .rating label input:focus:not(:checked) ~ .icon:last-child {
                                    color: #000;     top: 10px;
                                    text-shadow: 0 0 5px #09f;
                                  }
            
                            }
                        }
                        




                        .div6{
                            margin-top:10vh;
                            h1{
                                text-align: left;
                                color:black;
                                font-size:1.5em;
                                font-weight: bolder;;
                            }
                            p{ font-size:1.2em;
                                text-align: left;
                                color: grey;;
                            }
            
                        }



            
            
            
            }





    
    }
    }










    @media screen and (max-width:576px){
    
        #iotlab_product{
            min-height:100vh;
            padding-bottom:20vh;
        padding-top:13vh;
        
            #input{
                margin-left:auto;
                margin-right: auto;
                        
align-items: left;;
text-align: left;

                margin-bottom: 2vh;;
                input{
                    width:100%;background: #E9E5E4;
                    color:black;
                    border-radius:5px;
                    padding:2%;
                    border:0px solid red;
                }
                :-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #9C9C9C;
                    text-align: center;
                  }
                    
            }
        
        
          
        .div2{
            width: 100%;
            
            margin-top:10vh;
            
            .carousel{
                max-width: 100%;
                border:0px solid red;
                height:40vh;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                margin-bottom: 3vh;;
            }
            
            .carousel-control-prev-icon {
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230d0d0d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
            }
             
            .carousel-control-next-icon {
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230d0d0d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
            }
           
            .carousel-indicators li {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                background-color: #404040;
            }
            .carousel-inner{
                margin-bottom:10vh;
                img{
                    height:30vh;
                }
            }
            .carousel-indicators{
             
                li{
                    background-color: black;
                }
            }
            
            
            
            
            .div3{
                text-align: left;
                border-bottom:1px solid black;
            h1{
                font-size: 1.1em;;font-weight: bold;;
                        
            }
            h2{
                max-width:50%;
                font-size:01em;
                text-align: center;;
                background-color: #E9E5E4;
                color:black;
                padding:8px;
                font-weight: bold;;
            
                border-radius: 5px;;
            }
            h3{
                font-size:1em;
                font-weight:bolder;  font-weight: bold;;
                
            }
            p{
                font-size:1em;text-align: justify;
            }
            h4{
                color:black;
                span{
                    font-size:0.8em;
                    color:grey;
                }
            
            
                .checked {
                    color: orange;
                  }
                }
            }
            
            
            
            .div4{
            
            margin-top:5vh;
            
                .row3{
                    margin-top:2%;
                
                    p{
                        text-align: left;
                        width:100%;
                        font-size:0.9em;
                    }
                
                    h4{
                        
                        color:
                        #52796F;
                        font-size:1.5em;
                        text-align: left;;
                        font-weight: bold;;
                        span{
                            font-size: 0.6em;;
                        }
                
                    }
                
                    .input-group{
                        border-radius: 15px;;
                        padding-top:2%;
                    
                        width:100%;
                 
                        #quantity{
                            padding:5px;  width:10px;;;
                           text-align: center;font-size:1em;
                           border-top:1px solid grey;
                           border-bottom:1px solid grey;font-weight: bold;;
                        
                           
                        }
                        .button1{
                            font-size:1em;border-radius: 15px 0px 0px 15px;;font-size:0.9em; width: 30px;;height:100%;  border:1px solid grey;
                        }
                        .button2{
                            font-size:1em;border-radius: 0px 15px 15px 0px;;font-size:0.9em;height:100%;  border:1px solid grey;
                            width: 30px;;
                            margin-top:0px;
                        }
                        
                    }
                    
                    .card_button{
                      color:red;
                    }
                    .button1{
                        color:black;
                        padding:8%;        font-size:1em;
                        width:100%;
                        background: #1DDAF2;border-radius: 10px;;
                        border:0px solid red;
                        font-weight: bold;;

                    }
                    .button2{
                        color:black;
                        background: #BEF8FF;
                        padding:4%;
                font-size:1em;font-weight: bold;;
                        
                border:0px solid red;
                border-radius: 10px;;
                        width:100%;
                 
                        margin-top:10px;
                
                    }
                    .button3{     color:black;
                        padding:4%;
                font-size:1em;
                border:2px solid #58bdc9;
                border-radius: 10px;;
                        width:100%;
                background: white;
                font-weight: bold;;
                        
                        margin-top:10px;
                
                    }
                }
                
            
            
            }
            
            
            
            
            #heading{
                margin-top:5vh;
                
                text-align:left;
                h1{
                    font-size:1.3em;
                    font-weight: bolder;
                    color:black;
                }
                p{
                    text-align: justify;
                    font-size:0.9em;
                }
            }
            
            
            
            #table{
                margin-top:5vh;
                text-align: left;
                h1{
                    font-size:1.3em;
                    font-weight: bold;
                }
            
            table{
                font-size:0.9em;
                border-radius: 20px;;
                border-collapse: collapse;
                border:1px solid black;
                width:100%;
                tr td{
            border:1px solid grey;
                }
                tr:hover td{
                    background-color: #E9E5E4;
                }
            td{
                padding:8px;
            }
            
            #left{
                background-color: #E9E5E4;
                color:black;
            }
            #left:hover{
                background-color: white;;
            }
            #right{
                color:grey;
            }
            
            }
            
            
            }
            
            
            .div5{
                margin-top:10vh;
                h1{
                    text-align: left;
                    color:black;
                    font-size:1.5em;
                    font-weight: bolder;;
                }
                
            .card{ 
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border-radius: 30px;

                margin-top:5vh;
                width:90%;
                margin-left:5%;
                padding:5%;
                text-align: left;
                table{
                    #img{
                        width:25%;
                    }
                    img{
                    
                    border-radius: 50%;;
                    border:1px solid black;
                        width:80%;;
                    }
                
                    h1{
                        width:80%;
                        font-size:1.1em;
                
                    }
                }
                    p{
                        font-size: 1.1em;;
                        .checked{
                            color:orange;
                        }
                    }
                    h4{
                        font-size:0.9em;
                    }
             h5{
                 color:grey;
                 font-size:0.8em;
             }
             
               
            }
            
                           }                
        
            
                           .review_form{
                            margin-top: 10vh;;
                border-radius: 30px;

            text-align: left;
                            h1{
                                font-size:1.5em;
                                font-weight: bolder;;
                            }
            
                            form{
                                margin-top:5vh;
            
                                div{
                                    margin-top:2px;
                                }
                                label{
                                    font-size:1em;
                                }
                                input , textarea{
                                    font-size:1em;    background-color: #E9E5E4; border:0px solid red;
                                }
                                button{
                                    margin-left:auto;
                                    margin-right: auto;
                                    text-align: center;
                                    background-color: #1DDAF2;
                                    color:black;
                                    border:0px solid red;
                                }
                                .form-control{
                                    font-size:1em;
                                }
                                .rating {
                                    display: inline-block;
                                    position: relative;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 30px;
                                  }
                                  
                                  #star{
                                      margin-top:-20px;
                                      top:0px;
                                      
                                  }
                                  .rating label {
                                   
                                    position: absolute;
                            
                                    
                                    left: 0;
                                    height: 100%;
                                    cursor: pointer;
                                  }
                                  
                                  .rating label:last-child {
                                    position: static;    
                                  }
                                  
                                  .rating label:nth-child(1) {
                                    z-index: 5;    
                                  }
                                  
                                  .rating label:nth-child(2) {
                                    z-index: 4;    
                                  }
                                  
                                  .rating label:nth-child(3) {
                                    z-index: 3;    
                                  }
                                  
                                  .rating label:nth-child(4) {
                                    z-index: 2;    
                                  }
                                  
                                  .rating label:nth-child(5) {
                                    z-index: 1;     
                                  }
                                  
                                  .rating label input {
                                    position: absolute;
                                  
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                  }
                                  
                                  .rating label .icon {
                                    float: left;
                                    color: transparent;    
                                  }
                                  
                                  .rating label:last-child .icon {
                                    color: #000;    
                                  }
                                  
                                  .rating:not(:hover) label input:checked ~ .icon,
                                  .rating:hover label:hover input ~ .icon {
                                    color: orange;    
                                  }
                                  
                                  .rating label input:focus:not(:checked) ~ .icon:last-child {
                                    color: #000;     top: 10px;
                                    text-shadow: 0 0 5px #09f;
                                  }
            
                            }
                        }
                        
                        .div6{
                            margin-top:10vh;
                            h1{
                                text-align: left;
                                color:black;
                                font-size:1.5em;
                                font-weight: bolder;;
                            }
                            p{ font-size:0.9em;
                                text-align: left;
                                color: grey;;
                            }
            
                        }
            
            }
        
            
        }
        }



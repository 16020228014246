#watchlist{
@media screen and (min-width:996px){
    

    background-image: url('./bg.png');;
    background-repeat: no-repeat;
    background-size: cover;
    min-height:100vh;
    padding-top:20vh;
    padding-bottom:10vh;

.out{
    width:90%;
    #left{
        width:95%;
background-color: white;

padding:5%;

border-radius:20px;box-shadow: 4px 5px 57px 0px #00000040;

#image{
width:80%;
font-size: 12em;;
background: -webkit-linear-gradient(pink, rgb(88, 88, 228));
-webkit-background-clip: text;

-webkit-text-fill-color: transparent;


}

#data{
    width:80%;
    margin-left:10%;
}
h1{text-align:left;
    font-size:1.8em; i{
        margin-right:10px; 
    }
}
h2{text-align:left;
    font-size:1.2em;
    color:rgb(156, 148, 148);
    i{
        margin-right:10px;
    }
  
}
h3,h4{text-align:left;
    font-size:1.2em;
    i{
        margin-right:10px;
    }
   
}

h5{
    font-weight:100;
    text-align:left;
    color:grey;
    font-size:1.2em;
    i{
        margin-right:10px;
    }
}
button{
    width:100%;
    background-color:black;
    color:white;
    border-radius:20px;
    padding-top: 5%;
    padding-bottom: 5%;
    
}
button:hover{
    box-shadow: 4px 5px 60px 0px #00000040;
    
}

    }

.right{ width:80%;
    
    background-color: white;
    padding:5%;
    border-radius:25px;box-shadow: 4px 5px 57px 0px #00000040;
width:80%;
    #list{ 
        #heading{
    text-align: left;
    
            h1{
                font-size:2.5em;
            }
            p{
               font-size: 1.5em;color:grey;
            }
        }
        #items{
            background-color: white;;
            padding:2%;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border:1px solid grey;
            border-radius: 25px;;
        text-align:left;
        margin-top:5%;
            img{
                width:90%;
                height:20vh;
        
            }
            #phone{
                display: none;
            }
        
        h1{
            font-size:1.5em;font-weight:bold;
        }
        #itemquantity{
        font-size:1em;
        font-weight: bold;;
        margin-right:3%;
        }
        
        
        
        
        p{
            font-size:1em;
        }
        select{
            padding:1px;
            padding-left:3px;
            padding-right:3px;
            background-color: #E9E5E4;
            font-size:0.9em;
        }
        
        .input-group{
            border-radius: 15px;;
            border:1px solid grey;
        
            max-width:30%;
            margin-bottom:5px;
            #quantity{
                padding:2px; padding-left:4px ;
                padding-right:4px;text-align: center;font-size:0.9em;
            }
            .button1{
                font-size:1em;border-radius: 15px 0px 0px 15px;;font-size:0.9em;
            }
            .button2{
                font-size:1em;border-radius: 0px 15px 15px 0px;;font-size:0.9em;
            }
            
        }
        
        
        h2{
            font-size:1em;
            font-weight: bold;;
            margin-top:2%;
            width:100%;
            span{
                color:grey;
        
            }
        
        
            #span2{
                background-image: url('./itembg.png');
                color:white;
                background-repeat: no-repeat;
                background-size: cover;
                padding-left:25px;
                padding:3px;
                padding-left:25px;
                padding-right:10px;
            }
        }
        table{
            width:100%;
        }
        #delete{
            float:right;
        }
        
        
        
        
        }
    }
    
    
}
    
    }
    


}


}









#watchlist{
    @media screen and (min-width:576px) and (max-width:996px){
        
    
        background-image: url('./bg.png');;
        background-repeat: no-repeat;
        background-size: cover;
        min-height:100vh;
        padding-top:20vh;
        padding-bottom:10vh;
    
    .out{
        width:90%;
        #left{
            width:95%;
    background-color: white;
    
    padding:5%;
    
    border-radius:20px;box-shadow: 4px 5px 57px 0px #00000040;
    
 
#image{
    width:80%;
    font-size: 7em;;
    background: -webkit-linear-gradient(pink, rgb(88, 88, 228));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    
    }
    
    #data{
        width:80%;
        margin-left:10%;
    }
    h1{text-align:left;
        font-size:1.3em; i{
            margin-right:10px; 
        }
    }
    h2{text-align:left;
        font-size:1em;
        color:rgb(156, 148, 148);
        i{
            margin-right:10px;
        }
      
    }
    h3,h4{text-align:left;
        font-size:1em;
        i{
            margin-right:10px;
        }
       
    }
    
    h5{
        font-weight:100;
        text-align:left;
        color:grey;
        font-size:1em;
        i{
            margin-right:10px;
        }
    }
    button{
        width:100%;
        background-color:black;
        color:white;
        border-radius:20px;
        padding-top: 5%;
        padding-bottom: 5%;
        
    }
    button:hover{
        box-shadow: 4px 5px 60px 0px #00000040;
        
    }
    
        }
    
    .right{ width:80%;
        
        background-color: white;
        padding:5%;
        border-radius:25px;box-shadow: 4px 5px 57px 0px #00000040;
    width:80%;
        #list{ 
            #heading{
        text-align: left;
        
                h1{
                    font-size:1.8em;
                }
                p{
                   font-size: 1.2em;color:grey;
                }
            }
            #items{
                background-color: white;;
                padding:2%;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border:1px solid grey;
                border-radius: 25px;;
            text-align:left;
            margin-top:5%;
                img{
                    width:90%;
            height:20vh;
                }
                #phone{
                    display: none;
                }
                div{
                    margin:0px;
                    
                
                    padding:3px;
                }
            
            h1{
                font-size:1.2em;font-weight:bold;
            }
            #itemquantity{
            font-size:1em;
            font-weight: bold;;
            margin-right:3%;
            }
            p{
                font-size:1em;
            }
            select{
                padding:1px;
                padding-left:3px;
                padding-right:3px;
                background-color: #E9E5E4;
                font-size:0.9em;
            }
        
            .input-group{
                border-radius: 15px;;
                border:1px solid grey;padding:0px;
                width:40%;
                #quantity{
                    padding:2px; padding-left:4px ;
                    padding-right:5px;text-align: center;
                }
                .button1{
                    font-size:1em;border-radius: 15px 0px 0px 15px;;
                }
                .button2{
                    font-size:1em;border-radius: 0px 15px 15px 0px;;
                }
                
            }
        
        
            h2{
                font-size:1em;
                font-weight: bold;;
                margin-top:2%;
                width:100%;
                span{
                    color:grey;
            
                }
            
            
                #span2{
                    background-image: url('./itembg.png');
                    color:white;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding-left:25px;
                    padding:3px;
                    padding-left:25px;
                    padding-right:10px;
                }
            }
            table{
                width:100%;
            }
            #delete{
                float:right;
            }
            
            
            
            
            }
        }
        
        
    }
        
        }
        
    
    
    }
    
    
    }















    

#watchlist{
    @media screen and (max-width:576px) {
        
    
        background-image: url('./bg.png');;
        background-repeat: no-repeat;
        background-size: cover;
        min-height:100vh;
        padding-top:20vh;
        padding-bottom:10vh;
    
    .out{
        width:90%;
        #left{
            width:100%;
    background-color: white;
    
    padding:5%;
    margin-bottom:10vh;
    border-radius:20px;box-shadow: 4px 5px 57px 0px #00000040;
    
    #image{
        width:80%;
        font-size: 8em;;
        background: -webkit-linear-gradient(pink, rgb(88, 88, 228));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        
        }
        
    
    #data{
        width:80%;
        margin-left:10%;
    }
    h1{text-align:left;
        font-size:1.3em; i{
            margin-right:10px; 
        }
    }
    h2{text-align:left;
        font-size:1em;
        color:rgb(156, 148, 148);
        i{
            margin-right:10px;
        }
      
    }
    h3,h4{text-align:left;
        font-size:1em;
        i{
            margin-right:10px;
        }
       
    }
    
    h5{
        font-weight:100;
        text-align:left;
        color:grey;
        font-size:1em;
        i{
            margin-right:10px;
        }
    }
    button{
        width:100%;
        background-color:black;
        color:white;
        border-radius:20px;
        padding-top: 5%;
        padding-bottom: 5%;
        
    }
    button:hover{
        box-shadow: 4px 5px 60px 0px #00000040;
        
    }
    
        }
    
    .right{ width:80%;
        
        background-color: white;
        padding:5%;
        border-radius:25px;box-shadow: 4px 5px 57px 0px #00000040;
    width:80%;
        #list{ 
            #heading{
        text-align: left;
        
                h1{
                    font-size:1.8em;
                }
                p{
                   font-size: 1.2em;color:grey;
                }
            }
            #items{
                background-color: white;;
                padding:3%;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border:1px solid grey;
                border-radius: 25px;;
            text-align:left;
            margin-top:5%;
            width:90%;
         
            margin-left: 5%;
        
        div{
            margin:0px;
            
        
            padding:3px;
        }
            img{
                    width:100%;
                    height:15vh;
             
                }
            #desktop{
                display: none;
            }
            h1{
                font-size:1em;font-weight:bold;width:100%;
                text-align:left;
                margin-left:0px;
            }
            #itemquantity{
            font-size:0.7em;
            font-weight: bold;;
            margin-right:3%;
            }
        
        
        
            p{
                font-size:0.6em;
            }
            select{
                padding:1px;
                padding-left:2px;
                padding-right:2px;
                background-color: #E9E5E4;
                font-size:0.7em;width:40%;
                margin-left:3%;
            }
        
            .input-group{
                border-radius: 15px;;
                border:1px solid grey;padding:0px;padding:0px;
                width:60%;
                #quantity{
                    padding:2px; padding-left:2px ;
                    padding-right:2px;text-align: center;font-size:0.7em;
                }
                .button1{
                    font-size:0.7em;border-radius: 10px 0px 0px 10px;;
                }
                .button2{
                    font-size:0.7em;border-radius: 0px 10px 10px 0px;;
                }
                
            }
        
        
            h2{
                font-size:0.8em;
                font-weight: bold;;
                margin-top:2%;
                width:100%;
                span{
                    color:grey;
            
                }
            
            
                #span2{
                    background-image: url('./itembg.png');
                    color:white;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding-left:25px;
                    padding:3px;
                    float:right;
                    padding-left:25px;
                    padding-right:10px;
                }
            }
            table{
                width:100%;
            }
            #delete{
                float:right;
                font-size:0.7em;
                display: block;
            }
            
            
            
            
            }
        }
        
        
    }
        
        }
        
    
    
    }
    
    
    }

@media screen and(min-width:996px){
#Beacon_Sec{

    .div1{
        height:100vh;
        background-color: black;;
width:100vw;
        #nesteddiv1beacon{
margin-top:30vh;
margin-left:10%;

width:70%;
text-align: left;;
            h1{
                font-size:2.5em;
                color:white;
                span{
                    color:#1DDAF2;
                }
            }

            h2{
                font-size:4.5em;
                font-weight:600;
                font-family: 'Montserrat', sans-serif;
                color:white;
            }
            p{
                font-size:1.5em;
                color:white;

            }
        }
    }
    #beaconimg2{
        
        display: none;
        float:left;
        
        width:100%;
    }
    #phonep{
        display: none;
    }
#img1{
    margin-top:35vh;
    float:left;
    float:left;
    display: block;
width:95%;
}

#row2{
    height:100vh;
       
   width:100vw;
      background-image: url('../bg2.png');
      background-repeat: no-repeat;
      background-size: cover;
      text-align: left;
      font-family: 'Noto Sans', sans-serif;
      


  }

  #div2{
    margin-top:30vh;
margin-left:10%;
width:80%;

    h1{
        font-size:3em;
        color:black;
        font-weight: bolder;
    }

    p{
      font-weight: 400;
      text-align:justify;
        font-size:1.2em;
    }
}
  
#div2img{
    margin-top:30vh;
    max-width:100%;
    height:40vh;
    text-align: center;
    margin-left:auto;
margin-right:auto;
margin-left: 20%;;
}
    
}
}




@media screen and (min-width:576px) and (max-width:996px){
    #Beacon_Sec{
    
        .div1{
            height:100vh;
            background-color: black;;
    width:100vw;
            #nesteddiv1beacon{
    margin-top:30vh;
    margin-left:10%;
    width:70%;
    text-align: left;;
                h1{
                    font-size:2em;
                    color:white;
                    span{
                        color:#1DDAF2;
                    }
                }
    
                h2{
                    font-size:3.5em;
                    font-weight:600;
                    font-family: 'Montserrat', sans-serif;
                    color:white;
                }
                p{
                    font-size:1.2em;
                    color:white;
    
                }
            }
        }
    #img1{
        margin-top:35vh;
        float:left;
        
        width:100%;
    }
    #beaconimg2{
        
        display: none;
        float:left;
        
        width:100%;
    }
    #phonep{
        display: none;
    }
        


    #row2{
      
        height:100vh;
        width:100vw;
          background-image: url('../bg2.png');
          background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          font-family: 'Noto Sans', sans-serif;
          
    
    
      }
    
      #div2{
          margin-top:20vh;
    margin-left:10%;
    width:90%;

          h1{
              font-size:2em;
              color:black;
              font-weight: bolder;
          }
    
          p{
            font-weight: 400;
          
              font-size:1em;
          }
          
      }
      
      #div2img{
        margin-top:30vh;
        
        text-align: center;
        margin-left:auto;
    margin-right:auto;
    margin-left: 10%;;
    max-width:100%;
    
    }

    }
    }
    
    

    
@media screen and (max-width:576px) {
#Beacon_Sec{
  
        .div1{
            height:100vh;
            background-color: black;;
            
            background-repeat: no-repeat;
            background-size: cover;
            
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    #beaconimg2{
        
        
        width:100%;
    }
    


            #nesteddiv1beacon{
    margin-top:15vh;
    margin-bottom:0vh;
    width:90%;
    
    text-align: left;;
    
    
    margin-bottom: 0vh;;
    padding-bottom:0px;
    margin-left:5%;
            
           
    h1{
                    font-size:1.8em;
            
                    color:white;
                    span{
                        color:#1DDAF2;
                    }
                }
    
                h2{
                    font-size:3.5em;
                    font-weight:600;
                    font-family: 'Montserrat', sans-serif;
                    color:white;
                }
                p{
                    font-size:0.9em;
                    color:white;
     
     display:none;
    
                }
            }

            
        #img1{
            display: none;
            
        border:2px solid red;
    height:0vh;
        margin-top:0vh;
        }
        #phonep{
            width:80%;
            color: white;
            font-size:1.2em;
            text-align: left;;
            margin-left:5%;

            margin-top:0vh;
        }
    
        }


.div{
    margin-top:0px;
}

    
    #row2{
      
        height:100vh;
        width:100vw;
          background-image: url('./row2mobile.png');
          background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          font-family: 'Noto Sans', sans-serif;
          
    
    
      }
    
      #div2{
          margin-top:15vh;
    margin-left:5%;
    width:95%;

          h1{
              font-size:1.8em;
              color:black;
              font-weight: bolder;
          }
    
          p{
            font-weight: 400;
          
              font-size:0.9em;
          }
      }
      #div2img{
        
        text-align: center;
        margin-left:auto;
    margin-right:auto;
    margin-left: 25%;;
    width:50%;


}


        #divimg{
            display: none;
        }
    }
    }
    
    
    

    

@media screen  and (min-width:996px){
#logger{
    
    #row1{
  height:100vh;
width:100vw;
  background-image: url('./bg_desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
    }
  text-align: left;
font-family: 'Noto Sans', sans-serif;

  #div{
      margin-top:30vh;
      margin-left:10%;
      h1{
          font-size:3em;
          color:white;

          span{
              color: #1DDAF2;

          }
      }
      h2{
          font-size:5em;
          font-family: 'Montserrat', sans-serif;
          color:white;
          
      }
  }

  #row2{
    height:100vh;
       
   width:100vw;
   background-image: url('../bg2.png'); 
   background-repeat: no-repeat;
      background-size: cover;
      text-align: left;
      font-family: 'Noto Sans', sans-serif;
      


  }


  #div2{
      margin-top:30vh;
margin-left:10%;
width:80%;

      h1{
          font-size:3em;
          color:black;
          font-weight: bolder;
      }

      p{
        font-weight: 400;
        text-align:justify;
          font-size:1.2em;
      }
  }
}

}


@media screen  and (min-width:576px) and (max-width:996px){
    #logger{
        
        #row1{
      height:100vh;
    width:100vw;
 
    background-image: url('./bg_desktop.png');
    background-repeat: no-repeat;
      background-size: cover;
        }
      text-align: left;
    font-family: 'Noto Sans', sans-serif;
    
      #div{
          margin-top:30vh;
          margin-left:3%;
          h1{
              font-size:2em;
              color:white;
    
              span{
                  color: #1DDAF2;
    
              }
          }
          h2{
              font-size:3.3em;
              font-family: 'Montserrat', sans-serif;
              color:white;
              
          }
      }


      #row2{
      
        height:100vh;
        width:100vw;
      
        background-image: url('../bg2.png');
        background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          font-family: 'Noto Sans', sans-serif;
          
    
    
      }
    
      #div2{
          margin-top:20vh;
    margin-left:10%;
    width:90%;

          h1{
              font-size:2em;
              color:black;
              font-weight: bolder;
          }
    
          p{
            font-weight: 400;
          
              font-size:1em;
          }
      }
      




    }
    
    }


@media screen  and (max-width:576px){
    #logger{
        
        #row1{
      height:100vh;
    width:100vw;
      background-image: url('ss.png');
      background-repeat: no-repeat;
      background-size: cover;
        }
      text-align: left;
    font-family: 'Noto Sans', sans-serif;
    ;
      #div{
          margin-top:15vh;
          margin-left:3%;
          h1{
              font-size:2em;
              color:white;
    
              span{
                  color: #1DDAF2;
    
              }
          }
          h2{
              font-size:3.3em;
              font-family: 'Montserrat', sans-serif;
              color:white;
              
          }
      }



      #row2{
      
        height:100vh;
        width:100vw;
          background-image: url('./row2mobile.png');
          background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          font-family: 'Noto Sans', sans-serif;
          
    
    
      }
    
      #div2{
          margin-top:15vh;
    margin-left:5%;
    width:95%;

          h1{
              font-size:1.8em;
              color:black;
              font-weight: bolder;
          }
    
          p{
            font-weight: 400;
          
              font-size:1em;
          }
      }



    }
    
    }

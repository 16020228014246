
@media screen and (min-width: 996px) {
 
    .fa-angle-down{
        color: white;
        font-size: 3em;
    
            -moz-animation: bounce 2s infinite;
            -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;

            color: white;
        text-align: center;

          }
        

          @keyframes bounce{
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-30px);
            }
            60% {
              transform: translateY(-15px);
            }
          }
      
    
    
 

#header{
    padding-top:8vh;
    background-image:url('./newbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Noto Sans', sans-serif;
padding-bottom:10%;
margin-left:0px;
padding-left:0px;
    min-height:100vh;padding-bottom:10%;

    width:100vw;
    margin-top: 0px;;
    top:0px;
margin-bottom:0px;
#button2{
    display: none;;
}

    #img2{
        display:none;
    }
    img{
        height:100vh;
        width:100vw;
        margin-top: 0px;;
        float: left;
        
    }
.div{
    color:white;
    margin-top:20vh;
    margin-left:25%;
    width:70%;

    h1{
        font-family: 'Noto Sans', sans-serif;
        font-size:2em;
        text-align: left;

    }
    h2{
        font-family: 'Noto Sans', sans-serif;
    text-align: left;
        
        font-weight: bold;
        font-size:4em;
        span{
            color:#1DDAF2;
        }
    }
    p{
        text-align: left;
        
        span{

            color:#1DDAF2;
        }
        font-size:1.3em;
    }

    #first{
       background-color: #1DDAF2;
       color:blue;
       font-size:1.3em;
       padding:2%;
     padding-left:10%;
     padding-right:10%;
     border-radius:20px;
     font-weight:bold;
     float: left;
     width:50%;

    }

    
    #second{
        float: left;
     
        background-color:black;
        color:white;
        border:2px solid white;
        font-size:1.3em;
        margin-left:2%;
        padding:2%;
      padding-left:10%;
      padding-right:10%;
      border-radius:20px;
      font-weight:bold;
     }
 

}
    .headerbg{
        vertical-align: middle;
        margin-top: auto;
        margin-bottom: auto;
        width:80%;
        height: 60vh;
        margin-top:20vh;
        
  
    }
    #first:hover{
        background-color: black;
        color: #1DDAF2;
        border:2px solid #1DDAF2;
       
             }
             #second:hover{
                background-color: black;
                color: #1DDAF2;
                border:2px solid #1DDAF2;
               
                     }
@keyframes examp {
    0%  {height:60vh;width:80%;}
 
    50%   {height:62vh;width:82%;}
  
    100%  {height:60vh;width:80%;}
  }

    margin-top: 0px;;



    
@keyframes example {
    0%   {margin-top:0px;}
    
    50%  {margin-top:20px;}
    100% {margin-top:0px;}
  }

}
}





@media screen and (min-width: 576px) and (max-width:996px) {
 
 

    #header{
        
        background-image:url('./newbg.jpg');
        font-family: 'Noto Sans', sans-serif;
    text-align: left;

    margin-left:0px;
    padding-left:0px;
        
        width:100vw;
        margin-top: 0px;;
        top:0px;
    margin-bottom:0px;
    min-height:100vh;padding-bottom:10%;
    
        #img2{
            display:none;
        }
        img{
            height:100vh;
            width:100vw;
            margin-top: 0px;;
            
        }
        #button2{
            display: none;;
        }
        
    .div{
        color:white;
        margin-top:20vh;
        margin-left:25%;
    
        h1{
            font-family: 'Noto Sans', sans-serif;
            font-size:1.8em;
        }
        h2{
            font-family: 'Noto Sans', sans-serif;
        
            font-weight: bold;
            font-size:3em;
            span{
                color:#1DDAF2;
            }
        }
        p{
            span{
                color:#1DDAF2;
            }
            font-size:1.2em;
        }
    
        #first{
           background-color: #1DDAF2;
           color:blue;
           font-size:1em;
           padding:2%;
         padding-left:10%;
         padding-right:10%;
         border-radius:20px;
         font-weight:bold;
         width:90%;
        }
        #first:hover{
   background-color: black;
   color: #1DDAF2;
      border:2px solid #1DDAF2;

        }
        #second:hover{
            background-color: black;
            color: #1DDAF2;
            border:2px solid #1DDAF2;
           
                 }
    
        
        #second{
            background-color:black;
            color:white;
            border:2px solid white;
            font-size:1em;
            margin-left:2%;
            padding:2%;
          padding-left:10%;
          padding-right:10%;
          border-radius:20px;
          font-weight:bold;
         }
     
    
    }
        .headerbg{
            vertical-align: middle;
            margin-top: auto;
            margin-bottom: auto;
            width:80%;
            height: 60vh;
            margin-top:20vh;
            
            animation-iteration-count: infinite;
      animation-duration: 4s;
        }
        
    @keyframes example {
        0%  {height:60vh;width:80%;}
     
        50%   {height:62vh;width:82%;}
      
        100%  {height:60vh;width:80%;}
      }
    
        margin-top: 0px;;
    
        .fa-angle-down{
            color: white;
            font-size: 2em;
            width:100%;
            text-align: center;
        }

    
    }
    }






    
@media screen and (max-width: 576px) {
 
 

    #header{
        text-align: left;
    padding-top:5vh;
        background-image:url('./newbgphone.png');
        background-repeat: no-repeat;
        background-size: cover;
        font-family: 'Noto Sans', sans-serif;
    
    margin-left:0px;
    padding-left:0px;
        
        width:100vw;
        margin-top: 0px;;
        top:0px;
    margin-bottom:0px; min-height:100vh;padding-bottom:10%;
    
        #img2{
            display:none;
        }
        img{
            
            width:100vw;
            margin-top: 0px;;
            
        }
    .div{
        color:white;
        margin-top:10vh;
        margin-left:5%;
    
        h1{
            font-family: 'Noto Sans', sans-serif;
            font-size:1.3em;
        }
        h2{
            font-family: 'Noto Sans', sans-serif;
        
            font-weight: bold;
            font-size:2.3em;
            span{
                color:#1DDAF2;
            }
        }
        p{text-align: justify;
            span{
                color:#1DDAF2;
            }
            font-size:1em;
        }
    
     
    
    }

    #button2{
        width:100vw;
        align-items: center;
        text-align: center;
     margin-left: auto;
     margin-right: auto;
        margin-top:10%;
    }
    #first{
        background-color: #1DDAF2;
        color:black;
        font-size:1em;
        padding:2%;
      padding-left:10%;
      padding-right:10%;
      border-radius:10px;
      font-weight:bold;
      margin-left:5%;
    width:90%; 
    }
 
     
     #second{
         background-color:black;
         color:white;
         border:2px solid white;
         font-size:0.8em;
         margin-left:2%;
         padding:2%;
       padding-left:10%;
       padding-right:10%;
       border-radius:25px;
       font-weight:bold;
      }
        .headerbg{
            vertical-align: middle;
            margin-top: auto;
            margin-bottom: auto;
            width:100%;
            height:40vh;
            margin-top:2vh;
            
            animation-iteration-count: infinite;
      animation-duration: 4s;
        }
        #first:hover{
            background-color: black;
            color: #1DDAF2;
            border:2px solid #1DDAF2;
           
                 }
                 #second:hover{
                    background-color: black;
                    color: #1DDAF2;
                    border:2px solid #1DDAF2;
                   
                         }
    @keyframes example {
        0%  {height:40vh;width:40%;}
     
        50%   {height:42vh;width:42%;}
      
        100%  {height:40vh;width:40%;}
      }
    
        margin-top: 0px;;
    
        #button1{
            display:none;
        }
        .fa-angle-down{
            color: white;
            font-size: 2em;
            width:100%;
            text-align: center;
            margin-top: 10px;;
        }
        
    
    }
    }
    
    
    

@media (min-width: 998px) {

#beacon2{
    font-family: 'Noto Sans JP', sans-serif;
    
    min-height:100vh;
    padding-bottom:10%;
    
    ;
 .beacon_img{
        width:100%;
    }
#img2{
    display:none;
}

#img1{
    margin-top:30vh;
    height:50vh;
}

#btn2{
    display: none;
}

    .div{
        margin-left:20%;
        overflow-x: visible;
        margin-top:20vh;
        text-align:left;
   h1{
    font-family: 'Noto Sans JP', sans-serif;
       font-size: 5em;
       font-weight:400;
         
   color:#1DDAF2;
   font-weight: bolder;
   font-weight:900;
         
    }
   p{
       font-size: 1.5em;;
       float:left;
       font-weight:500;
         
   }
   button{
       width:40%;
       font-size: 1.2em;;
   font-weight: bold;;
   border-radius: 20px;
   padding-left:10px;
   padding-right:10px;
   padding-top:10px;
padding-bottom:10px;
}

#button1{
    background-color:black;
    color:white;
    border:0px solid black;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

    #button2{
        margin-left:2%;
     background-color:white;
     color:black;
      border:2px solid black;
    }
}

.fa-angle-down{
    color: black;
    font-size: 2em;
    width:100%;
    text-align: center;
    margin-top:5vh;
}

}
}




@media (min-width: 576px) and (max-width: 998px) {

    #beacon2{
        
        min-height:90vh;
    padding-bottom:10%;
    
     .beacon_img{
            width:100%;
        }
        #img2{
            display:none;
        }
        
#img1{
    margin-top:30vh;
    
}
#btn2{
    display: none;
}
#btn1{
    margin-left:0px;
}
        .div{
            margin-left:10%;
            overflow-x: visible;
            margin-top:20vh;
           
            h1{
                font-family: 'Noto Sans JP', sans-serif;
                   font-size: 3em;
               color:#1DDAF2;
               font-weight:900;
         text-align: left;

               font-weight: bolder;
                }
               p{
                   font-size: 1.2em;;
                   float:left;
                   text-align: left;
         
               }
               button{
                   width:40%;
                   font-size: 1em;;
               font-weight: bold;;
               border-radius: 20px;
               padding-left:10px;
               padding-right:10px;
               padding-top:10px;
            padding-bottom:10px;
            float:left;
            }
            
            #button1{
                background-color:black;
                color:white;
                border:0px solid black;
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
            }
            
                #button2{
                    margin-left:2%;
                 background-color:white;
                 color:black;
                  border:2px solid black;
                }
            
    }



    .fa-angle-down{
        color: black;
        font-size: 2em;
        width:100%;
        text-align: center;
        margin-top:5vh;
    }
    
    }
    




}



@media (max-width: 576px) {

    #beacon2{
        
        min-height:90vh;
    padding-bottom:10vh;

    
     .beacon_img{
            width:100%;
        }

        #btn1{
            display: none;
        }
        #btn2{
            display: block;
            width:100%;
            margin-top:15%;
        }
        
     
        .div{
    
            margin-top:20%;
    margin-left: 0px;;
            width:100%;
        text-align:center;

        h1{
            font-family: 'Noto Sans JP', sans-serif;
             
               font-size: 4em;
           color:#1DDAF2;
           font-weight:900;
        

            }
           p{
            font-family: 'Noto Sans JP', sans-serif;
               font-size: 1em;;
               float:center;
               
        font-weight:300;
            }
           button{
               width:40%;
               font-size: 1em;;
           font-weight: bold;;
           border-radius: 20px;
           padding-left:10px;
           padding-right:10px;
           padding-top:10px;
        padding-bottom:10px;
        }
        
        #button1{
            background-color:black;
            color:white;
            border:0px solid black;
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        }
        
            #button2{
                margin-left:2%;
             background-color:white;
             color:black;
              border:2px solid black;
            }
        
    }

    button{
        width:45%;
        font-size: 0.8em;;
    font-weight: bold;;
    border-radius: 20px;
    padding-left:10px;
    padding-right:10px;
    padding-top:10px;
 padding-bottom:10px;
 }
 
 #button1{
     margin-left:3%;

     background-color:black;
     color:white;
     border:0px solid black;
     box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
     font-size:1em;
            border-radius: 10px;;
            
 }
 
     #button2{
         margin-left:2%;
      background-color:white;
      color:black;
       border:2px solid black;
       font-size:1em;
            border-radius: 10px;;
            
     }

     .fa-angle-down{
        color: black;
        font-size: 2em;
        width:100%;
        text-align: center;
        margin-top:5vh;
    }
    
 
    }
    




}

#swiper2{
#p{
  text-align: center;
  width:100%;
  color:grey;
  
}
.swiper-container {
  width: 300px;
  height: 100%;
  
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}






}

@media screen and (min-width:996px){
  

    #swiper{
    #root { height: 100% }
    
    
    
      
      
      font-size: 14px;
      
      color: #000;
      margin: 0;
      padding: 0;
    
    .swiper-container {
      width: 100%;
      height: 100%;
      margin-top:0px;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      width:100%;
    height:70vh;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .swiper-container {
      width: 80vw;
      margin-left:10vw;
      height: 80vh;;
    
      margin: 20px auto;
    }
    .append-buttons {
      text-align: center;
      margin-top: 20px;
    }
    
    .append-buttons button {
      display: inline-block;
      cursor: pointer;
      border: 1px solid #007aff;
      color: #007aff;
      text-decoration: none;
      padding: 4px 10px;
      border-radius: 4px;
      margin: 0 10px;
      font-size: 13px;
    }
    
    
    .card{
        background-color:#2C2C2C;
    
        color:white;box-shadow:0px 10px 30px  grey;
    
    
        width:95%;
        padding:2%;
        margin-left: 2%;;
        margin-right:2%;
        border-radius: 25px;;
        height:60vh;
        #img{
           width:100%;
           height:30vh;
    
            border-radius: 25px;;
    
        }
        .carousel_div{
            margin-top:2vh;
            width:95%;
            margin-left:3%;
            h1{
                font-size:1.3em;
    
            }
            p{
                font-size:0.9em;
                text-align: left;
            }
        }
    }
    
    .carousel-inner{
        width:80%;
        margin-left:10%;
    }
    
    .carousel{
        margin-left:5%;
        width:90%;
        margin-top:5%;
        height:70vh;
        #img{
            height:10vh;height:30vh;
           
        }
    
    }
    .carouselphone{
        display:none;
    
    }
    .carousel-control-prev-icon{
    color:#2C2C2C;
    padding:5%;
        background-color: black;;
        border-radius: 5px;;
    
    
    }
    .carousel-control-next-icon{
    color:#2C2C2C;background-color: black;;
    border-radius: 5px;;
        
        
            
    }
    
    
    
    
    
    }
    
    
    
    }
    
    
    
    @media screen and (min-width:576px) and (max-width:996px){
      
    
      #swiper{
      #root { height: 100% }
      
      
      
        
        
        font-size: 14px;
        
        color: #000;
        margin: 0;
        padding: 0;
      
      .swiper-container {
        width: 100%;
        height: 100%;
        margin-top:20vh;
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        width:90%;
      height:70vh;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .swiper-container {
        width: 90vw;
        margin-left:5vw;
        height: 80vh;;
      
        margin: 20px auto;
      }
      .append-buttons {
        text-align: center;
        margin-top: 20px;
      }
      
      .append-buttons button {
        display: inline-block;
        cursor: pointer;
        border: 1px solid #007aff;
        color: #007aff;
        text-decoration: none;
        padding: 4px 10px;
        border-radius: 4px;
        margin: 0 10px;
        font-size: 13px;
      }
      
      
      .card{
          background-color:#2C2C2C;
      
          color:white;box-shadow:10px 10px 10px  grey;
      
      
          width:95%;
          padding:2%;
          margin-left: 2%;;
          margin-right:2%;
          border-radius: 25px;;
          height:60vh;
          #img{
             width:100%;
             height:30vh;
      
              border-radius: 25px;;
      
          }
          .carousel_div{
              margin-top:2vh;
              width:95%;
              margin-left:3%;
              h1{
                  font-size:1em;
      
              }
              p{
                  font-size:0.7em;
                  text-align: left;
              }
          }
      }
      
      .carousel-inner{
          width:80%;
          margin-left:10%;
      }
      
      .carousel{
          margin-left:5%;
          width:90%;
          margin-top:5%;
          height:70vh;
          #img{
              height:10vh;height:30vh;
             
          }
      
      }
      .carouselphone{
          display:none;
      
      }
      .carousel-control-prev-icon{
      color:#2C2C2C;
      padding:5%;
          background-color: black;;
          border-radius: 5px;;
      
      
      }
      .carousel-control-next-icon{
      color:#2C2C2C;background-color: black;;
      border-radius: 5px;;
          
          
              
      }
      
      
      
      
      
      }
      
      
      
      }
      
    



@media screen  and (min-width:996px) {
    

#cart{
    background-image: url('./bg.png');
    min-height:100vh;
    padding-bottom:10%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top:10%;
    
#phone{
    display:none;
}
    .heading{
        text-align: left;
        h1{
            font-size:2em;
            font-weight:bold;
        }
        p{
            font-size:1.2em;
        }
    }
#items{
    background-color: white;;
    padding:2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border:1px solid grey;
    border-radius: 25px;;
text-align:left;
margin-top:5%;
    img{
        width:90%;

    }

h1{
    font-size:1.5em;font-weight:bold;
}
#itemquantity{
font-size:1em;
font-weight: bold;;
margin-right:3%;
}




p{
    font-size:1em;
}
select{
    padding:1px;
    padding-left:3px;
    padding-right:3px;
    background-color: #E9E5E4;
    font-size:0.9em;
}

.input-group{
    border-radius: 15px;;
    border:1px solid grey;

    max-width:30%;
    margin-bottom:5px;
    #quantity{
        padding:2px; padding-left:4px ;
        padding-right:4px;text-align: center;font-size:0.9em;
    }
    .button1{
        font-size:1em;border-radius: 15px 0px 0px 15px;;font-size:0.9em;
    }
    .button2{
        font-size:1em;border-radius: 0px 15px 15px 0px;;font-size:0.9em;
    }
    
}


h2{
    font-size:1em;
    font-weight: bold;;
    margin-top:2%;
    width:100%;
    span{
        color:grey;

    }


    #span2{
        background-image: url('./itembg.png');
        color:white;
        background-repeat: no-repeat;
        background-size: cover;
        padding-left:25px;
        padding:3px;
        padding-left:25px;
        padding-right:10px;
    }
}
table{
    width:100%;
}
#delete{
    float:right;
}




}
.checkout{
    margin-top:5%;
    margin-left:5%;
    border-radius:25px;
    border:1px solid grey;
    padding:5%;
text-align: left;;  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width:90%;
    table{
        width:100%;
        border-collapse: separate;
        border-spacing:10px;

    }
    background-color:white;
#left{
    text-align: left;
    width:50%;  
}    
#right{
    text-align: right;;  width:50%;   
}
h1{
    font-size:1.5em; font-weight: bold;;
    text-align: left;

}
#checkoutbtn{
    margin-top:5px;
    
    background-color: #1DDAF2;
    color:black;
    width:100%;
}
h2{
    margin-top:20px;;
    font-size:1.5em;font-weight:bold;

}
#addaddress{
    margin-top:5px;
    
    background-color: white;
    color:black;
    border:1px solid black;
    width:100%;

}

}
}

}




@media screen  and (max-width:996px) and (min-width:576px) {
    

    #cart{
        background-image: url('./bg.png');
        min-height:100vh;
        padding-bottom:10%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top:15vh;
        #desktop{
            display:none;
        }
        #phone{
            display: block;
        }     
    .heading{
            text-align: left;
            h1{
                font-size:2em;
                font-weight:bold;
            }
            p{
                font-size:1.2em;
            }
        }
    #items{
        background-color: white;;
        padding:2%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border:1px solid grey;
        border-radius: 25px;;
    text-align:left;
    margin-top:5%;
        img{
            width:90%;
    
        }
        div{
            margin:0px;
            
        
            padding:3px;
        }
    
    h1{
        font-size:1.5em;font-weight:bold;
    }
    #itemquantity{
    font-size:1em;
    font-weight: bold;;
    margin-right:3%;
    }
    p{
        font-size:1em;
    }
    select{
        padding:1px;
        padding-left:3px;
        padding-right:3px;
        background-color: #E9E5E4;
        font-size:0.9em;
    }

    .input-group{
        border-radius: 15px;;
        border:1px solid grey;padding:0px;
        width:40%;
        #quantity{
            padding:2px; padding-left:4px ;
            padding-right:5px;text-align: center;
        }
        .button1{
            font-size:1em;border-radius: 15px 0px 0px 15px;;
        }
        .button2{
            font-size:1em;border-radius: 0px 15px 15px 0px;;
        }
        
    }


    h2{
        font-size:1em;
        font-weight: bold;;
        margin-top:2%;
        width:100%;
        span{
            color:grey;
    
        }
    
    
        #span2{
            background-image: url('./itembg.png');
            color:white;
            background-repeat: no-repeat;
            background-size: cover;
            padding-left:25px;
            padding:3px;
            padding-left:25px;
            padding-right:10px;
        }
    }
    table{
        width:100%;
    }
    #delete{
        float:right;
    }
    
    
    
    
    }
    .checkout{
        margin-top:5%;
      
        border-radius:25px;
        border:1px solid grey;
        padding:5%;
    text-align: left;;  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width:100%;
        table{
            width:100%;
            border-collapse: separate;
            border-spacing:10px;
    
        }
        background-color:white;
    #left{
        text-align: left;
        width:50%;  
    }    
    #right{
        text-align: right;;  width:50%;   
    }
    h1{
        font-size:1.5em; font-weight: bold;;
        text-align: left;
    
    }
    #checkoutbtn{
        margin-top:5px;
        
        background-color: #1DDAF2;
        color:black;
        width:100%;
    }
    h2{
        margin-top:20px;;
        font-size:1.5em;font-weight:bold;
    
    }
    #addaddress{
        margin-top:5px;
        
        background-color: white;
        color:black;
        border:1px solid black;
        width:100%;
    
    }
    
    }
    }
    
    }



@media screen  and (max-width:576px) {
    

    #cart{
        background-image: url('./bg.png');
        min-height:100vh;
        padding-bottom:10%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top:15vh;
        
    #desktop{
        display:none;
    }
    #phone{
        display: block;
    }

        .heading{
            text-align: left;
            h1{
                font-size:1.2em;
                font-weight:bold;
            }
            p{
                font-size:0.9em;
            }
        }
    #items{
        background-color: white;;
        padding:3%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border:1px solid grey;
        border-radius: 25px;;
    text-align:left;
    margin-top:5%;
    width:90%;
 
    margin-left: 5%;

div{
    margin:0px;
    

    padding:3px;
}
    img{
            width:100%;
     
        }
    
    h1{
        font-size:1em;font-weight:bold;width:100%;
        text-align:left;
        margin-left:0px;
    }
    #itemquantity{
    font-size:0.7em;
    font-weight: bold;;
    margin-right:3%;
    }



    p{
        font-size:0.6em;
    }
    select{
        padding:1px;
        padding-left:2px;
        padding-right:2px;
        background-color: #E9E5E4;
        font-size:0.7em;width:40%;
        margin-left:3%;
    }

    .input-group{
        border-radius: 15px;;
        border:1px solid grey;padding:0px;padding:0px;
        width:60%;
        #quantity{
            padding:2px; padding-left:2px ;
            padding-right:2px;text-align: center;font-size:0.7em;
        }
        .button1{
            font-size:0.7em;border-radius: 10px 0px 0px 10px;;
        }
        .button2{
            font-size:0.7em;border-radius: 0px 10px 10px 0px;;
        }
        
    }


    h2{
        font-size:0.8em;
        font-weight: bold;;
        margin-top:2%;
        width:100%;
        span{
            color:grey;
    
        }
    
    
        #span2{
            background-image: url('./itembg.png');
            color:white;
            background-repeat: no-repeat;
            background-size: cover;
            padding-left:25px;
            padding:3px;
            float:right;
            padding-left:25px;
            padding-right:10px;
        }
    }
    table{
        width:100%;
    }
    #delete{
        float:right;
        font-size:0.7em;
    }
    
    
    
    
    }
    .checkout{
        margin-top:5%;
        margin-left:5%;
        border-radius:25px;
        border:1px solid grey;
        padding:5%;
    text-align: left;;  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width:90%;
        table{
            width:100%;
            border-collapse: separate;
            border-spacing:5px;
    font-size: 1em;;
        }
        background-color:white;
    #left{
        text-align: left;    font-size: 0.9em;;
        width:50%;  
    }    
    #right{
        text-align: right;;  width:50%;   font-size: 0.9em;
    }
    h1{
        font-size:1.2em; font-weight: bold;;
        text-align: left;
    
    }
    #checkoutbtn{
        margin-top:5px;
    
        background-color: #1DDAF2;
        color:black;
        width:100%;
    }
    h2{
        margin-top:20px;;
        font-size:1.2em;font-weight:bold;
    
    }
    #addaddress{
        margin-top:5px;
        
        background-color: white;
        color:black;
        border:1px solid black;
        width:100%;
    

    }
    form{
        font-size: 0.9em;
    }
    
    }
    }
    
    }